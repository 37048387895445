import { IColumn, Link, PrimaryButton, SelectionMode, Stack, Text } from '@fluentui/react';
import { FC, useState } from 'react';
import { getBulkRegistrationImportPreview, downloadBulkRegistrationImportTemplate, postBulkRegistrationImport } from '../ApiService';
import { useMsal, useAccount } from "@azure/msal-react";
import FileUploadButton, { IUploadedFile } from './FileUploadButton';
import Loader from './Loader';
import List, { IDocument } from './List';
import { UserInvitation } from '../model/UserInvitation';
import { ImportError } from '../model/ImportError';
import { StoragePurpose } from '../Storage';

enum ImportState {
    None = 0,
    PreparingPreview = 1,
    Preview = 2,
    Importing = 3,
    ImportComplete = 4
}

interface ITableRow extends IDocument {
    key: string;
    student: string;
    programs: string;
    level: string;
    contacts: string[];
}

interface IData {
    tournamentId: string;
    importCompleted: () => void;
}

const BulkImporter : FC<IData> = ({tournamentId, importCompleted}) => { 
    const { instance, accounts } = useMsal(); 
    const account = useAccount(accounts[0] || {});
    
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [currentState, setCurrentState] = useState<ImportState>(ImportState.None);
    const [previewItems, setPreviewItems] = useState<IDocument[]>();
    const [importErrors, setImportErrors] = useState<ImportError[]>();
    const [filename, setFilename] = useState<string>();

    const downloadImportTemplate = async () => {
        
        var fileData = await downloadBulkRegistrationImportTemplate(instance, account!, tournamentId);

        if (typeof fileData === 'number') {
            alert('Sorry, something went wrong');
            return;
        }

        const link = document.createElement('a');
        link.href = fileData.Uri;
        link.download = fileData.Filename;
        link.click();
    }

    const onFileUploaded = async (uploadedFile: IUploadedFile) => {

        setFilename(filename);
        setCurrentState(ImportState.PreparingPreview);

        var result = await getBulkRegistrationImportPreview(instance, account!, tournamentId, uploadedFile.relativeFilePath);

        if (result !== undefined) {            
            buildPreviewView(result[0]);
            setImportErrors(result[1]);
            setCurrentState(ImportState.Preview);
        }
    }

    const completeImport = async () => {
        var result = await postBulkRegistrationImport(instance, account!, tournamentId, filename!);

        if (result) {
            importCompleted();
        }
        else {
            alert('Sorry, something went wrong. Please try again.');
        }
    }

    const [columns, ] = useState<IColumn[]>([
        {
          key: 'column1',
          name: 'Contacts',
          fieldName: 'contacts',
          minWidth: 75,
          maxWidth: 200,
          isRowHeader: true,
          isResizable: true,
          isSorted: false,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          data: 'string',
          isPadded: true,
          onRender: (item:ITableRow)=> {
            return <span>
              {item.contacts.map((c,idx) => {
              return <Stack key={idx}>
                  <Text>{c}</Text>
                  <Text variant='small'>Viewer</Text>
                  {item.contacts.length > 1 && idx !== item.contacts.length - 1 ? <br /> : null}
              </Stack>
              })
            }
            </span>       
          }
        },
      {
        key: 'column2',
        name: 'Student',
        fieldName: 'student',
        minWidth: 75,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
      },    
        {
            key: 'column3',
            name: 'Program(s)',
            fieldName: 'programs',
            minWidth: 35,
            maxWidth: 75,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isCollapsible: true,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
        },
        {
            key: 'column4',
            name: 'Level',
            fieldName: 'level',
            minWidth: 35,
            maxWidth: 75,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isCollapsible: true,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
        }
    ]);

    const buildPreviewView = (preview: UserInvitation[]) => {

        var tableRows = new Array<ITableRow>();
            
        var key = 0;

        for(let invitation of preview) {
            let title: string = invitation.Emails.join(', ');

            for(let student of invitation.Students) {
                tableRows.push({
                    contacts: invitation.Emails,
                    student: `${student.FirstName} ${student.LastName}`,
                    key: key.toString(),
                    level: student.LevelId,
                    programs: student.ProgramIds.join(', '),
                    getTitle: () => title
                });

                key++;
            }            
        }

        setPreviewItems(tableRows);
    }

    return (
        <>   
            {currentState === ImportState.None ? 
                <Stack tokens={{childrenGap:10}}>         
                                    
                    <Text variant="medium">Once you have <Link onClick={downloadImportTemplate}>downloaded the template</Link> and filled it in, please import it below.</Text>
                    <Text variant="medium">You will be shown a preview before any registrations are imported.</Text>
                    {!isUploading && <FileUploadButton
                        Purpose={StoragePurpose.BulkImportRegistrations}
                        IsUploading={(uploading) => setIsUploading(uploading)}
                        MaxSizeInMb={5}
                        FileUploaded={onFileUploaded!}
                        TournamentId={tournamentId}
                        Label="Import registrations via template"
                        style={{ textDecoration: "underline" }}
                        SupportedFormats="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                    }
                </Stack> : null}

            {currentState === ImportState.PreparingPreview ? <Loader Text="Building preview, just a moment..." /> : null}

            {currentState === ImportState.Preview ? 
                (previewItems !== undefined ? 
                    <Stack tokens={{childrenGap:10}}>
                        {importErrors !== undefined && importErrors.length > 0 ? importErrors.map((ie,idx) => <Text key={idx} style={{color:'red'}}>Row {ie.Row}: {ie.Error}</Text>) : null}
                        <List columns={columns} items={previewItems} selectionMode={SelectionMode.none}  /> 
                        <PrimaryButton disabled={filename === undefined || (importErrors !== undefined && importErrors.length > 0)} onClick={completeImport}>Import</PrimaryButton>
                    </Stack>
                : null)
            : null}
            
        </>
    )
}

export default BulkImporter;