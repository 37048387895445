import { AccountInfo, AuthError, IPublicClientApplication } from '@azure/msal-browser';
import { CurriculumItem } from './model/CurriculumItem';
import { Membership, MembershipStatus } from './model/Membership';
import { ICustomValue, Student } from './model/Student';
import { StudentPromotion } from './model/StudentPromotion';
import { UserProfile } from './model/UserProfile';
import {StudentCurriculumSubmission, SubmissionStatus} from './model/StudentCurriculumSubmission';
import { Rubric, RubricType } from './model/Rubric';
import { Resource } from './model/Resource';
import {DayOfWeek, Instructor, Lesson} from './model/Lesson';
import { ProvisionResult } from './model/ProvisionResult';
import { ActivityFeedItem } from './model/ActivityFeedItem';
import { IReviewAuthor, Review } from './model/Review';
import { ReviewFeedback } from './model/ReviewFeedback';
import {QueryResult} from './model/QueryResult';
import { SubmissionEnv } from './model/SubmissionEnv';
import {StudentDashboardModel} from './model/StudentDashboardModel';
import {ReviewDashboardViewModel} from './model/ReviewDashboardViewModel';
import { ReviewHistoryViewModel } from './model/ReviewHistoryViewModel';
import { EventModel } from './model/EventModel';
import { ProvisionPreview} from './model/ProvisionPreview';

import { TenantEnvironment } from './model/TenantEnvironment';
import { Level } from './model/Level';
import { RubricPoints } from './model/RubricPoints';
import { Program } from './model/Program';
import { InvitationTokenState, UserInvitation, UserInvitationData } from './model/UserInvitation';
import { Audience } from './model/Audience';
import { IPricingStrategy, IPurchaseGroup, IRegistrationOption, ITournamentCredentialTemplate, ITournamentStakeholder, Tournament, TournamentRole } from './model/Tournament';
import { IPurchasePrerequisite, TournamentEvent } from './model/TournamentEvent';
import { ImportError } from './model/ImportError';
import { IBracketStatus, ILeaderboardRow, TournamentLeaderboard } from './model/TournamentLeaderboard';
import { Feedback } from './model/Feedback';
import { ScheduleViewModel } from './model/ScheduleViewModel';
import { IStudentRegistration, ITournamentCatalogItemConfig, ITournamentCatalogItemPurchase, ITournamentEventRegistration, TournamentRegistration } from './model/TournamentRegistration';
import { ILineItem, Transaction } from './model/Transaction';
import { TournamentRegistrationViewModel } from './model/TournamentRegistrationViewModel';
import { TransactionViewModel } from './model/TransactionViewModel';
import { TournamentEntryViewModel } from './model/TournamentEntryViewModel';
import { IReactionValue, Reaction } from './model/Reaction';
import { ViralJoinEnv } from './model/ViralJoinEnv';
import { TrainingFacility } from './model/TrainingFacility';
import { Category } from './model/Category';
import { IResult, IResultRow, ReviewCollection } from './model/ReviewCollection';
import { IRowValue, ITournamentStatus, TournamentStatus } from './model/TournamentStatus';
import { ILandingPageEvent, ILandingPageTournament, TenantLandingPageViewModel } from './model/TenantLandingPageViewModel';
import { ITournamentDivisionViewModel, ITournamentEvent, ITournamentStakeholderRole, ITournamentStakeholderRoleWithId, TournamentReviewDashboardViewModel } from './model/TournamentReviewDashboardViewModel';
import { IBracketedAthlete, ITournamentBracket, TournamentBracket } from './model/TournamentBracket';
import { ITournamentRound } from './model/TournamentRound';
import { ITournamentMatch, Score, TournamentMatch } from './model/TournamentMatch';
import { BracketReviewViewModel } from './model/BracketReviewViewModel';
import { ReviewPolicy } from './model/ReviewPolicy';
import { CatalogItem, ICatalogItemWithQuantity, IMembershipInput, IUserInput, IUserInputOption, IUserInputWithStudents } from './model/CatalogItem';
import { TournamentLandingPageViewModel } from './model/TournamentLandingPageViewModel';
import { IDivisionRuleGroup } from './model/DivisionRuleGroup';
import { IFileDownload } from './model/FileDownload';
import { IAthleteCallState, IDivisionStatus } from './model/EventStatus';
import { ITournamentDivision } from './model/TournamentDivision';
import { IAthleteCallRequest } from './model/AthleteCallRequest';
import { IChangeRegistrationRequest } from './model/ChangeRegistrationRequest';
import { IAdminTournamentViewModel } from './model/AdminTournamentViewModel';
import { ITimezoneViewModel } from './model/TimezoneViewModel';
import { TournamentSummary } from './model/TournamentSummary';
import { IValidationResult } from './model/ValidationResult';
import { IRegistrationHistoryViewModel } from './model/RegistrationHistoryViewModel';
import { ICreateInvoiceRequest, IEventChange, IInvoice } from './model/Invoice';
import { IInvoicesViewModel } from './model/InvoicesViewModel';

export interface IResultWithStatusCode {
    statusCode: number;
    data: any;
}
const getToken = async(scopes: string[], instance: IPublicClientApplication, accountInfo: AccountInfo | null) : Promise<string> => {
    try {
        var result = await instance.acquireTokenSilent({
            account: accountInfo !== null ? accountInfo : undefined,
            scopes: scopes,
            forceRefresh:false
        });

        return result.accessToken;
     } 
     catch (err) {
        const error = err as AuthError;     
        console.log(err);     
        // chrome private tab fallback => if acquireTokenSilent fails, we try acquireTokenRedirect method
        if(error.errorCode.includes("monitor_window_timeout")) {
            instance.acquireTokenRedirect({
            account: accountInfo !== null ? accountInfo : undefined,
            scopes: scopes,
            state: document.location.href
        });
        }
        else if (error.errorCode.includes("interaction_required")) {
            //TODO: bump this up to the login UI, this will redirect to the pick one of goog, ms, apple
            instance.acquireTokenRedirect({
                account: accountInfo !== null ? accountInfo : undefined,
                scopes: scopes,
                state: document.location.href
            }); 
        }
        else {              
            console.log(error);
        }
     }

     return '';
}

export const downloadTenantUsers = async (instance: IPublicClientApplication, account: AccountInfo) : Promise<IFileDownload | number> => {
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}membership/download`,accessToken);
    
    if(result.data === undefined) {
        return result.statusCode;
    }

    return { Filename: result.data.filename, Uri: result.data.value };
}

export const downloadTournamentParticipants = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string) : Promise<IFileDownload | number> => {
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}participants/download/${tournamentId}`,accessToken);
    
    if(result.data === undefined) {
        return result.statusCode;
    }

    return { Filename: result.data.filename, Uri: result.data.value };
}

export const downloadTournamentTransactions = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string) : Promise<IFileDownload | number> => {
    var accessToken = await getToken([process.env.REACT_APP_PAYMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_PAYMENT_URI as string}transactions/download/${tournamentId}`,accessToken);
    
    if(result.data === undefined) {
        return result.statusCode;
    }

    return { Filename: result.data.filename, Uri: result.data.value };
}

export const downloadTournamentRegistrations = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId:string) : Promise<IFileDownload | number> => {
    var accessToken = await getToken([process.env.REACT_APP_PAYMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_PAYMENT_URI as string}registrations/download/${tournamentId}`,accessToken);
    
    if(result.data === undefined) {
        return result.statusCode;
    }

    return { Filename: result.data.filename, Uri: result.data.value };
}

export const downloadTournamentBadgeForStudent = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId:string, studentId: string) : Promise<IFileDownload | number> => {
    var accessToken = await getToken([process.env.REACT_APP_PAYMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_PAYMENT_URI as string}badges/download/${tournamentId}/student/${studentId}`,accessToken);
    
    if(result.data === undefined) {
        return result.statusCode;
    }

    return { Filename: result.data.filename, Uri: result.data.value };
}

export const downloadTournamentBadges = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId:string) : Promise<IFileDownload | number> => {
    var accessToken = await getToken([process.env.REACT_APP_PAYMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_PAYMENT_URI as string}badges/download/${tournamentId}`,accessToken);
    
    if(result.data === undefined) {
        return result.statusCode;
    }

    return { Filename: result.data.filename, Uri: result.data.value };
}

export const downloadTournamentCredentials = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId:string, templateId: string) : Promise<IFileDownload | number> => {
    var accessToken = await getToken([process.env.REACT_APP_PAYMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_PAYMENT_URI as string}credentials/download/${tournamentId}/template/${templateId}`,accessToken);
    
    if(result.data === undefined) {
        return result.statusCode;
    }

    return { Filename: result.data.filename, Uri: result.data.value };
}

export const downloadBlankTournamentCredential = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string, templateId: string | undefined): Promise<IFileDownload | number> => {
    var accessToken = await getToken([process.env.REACT_APP_PAYMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_PAYMENT_URI as string}credentials/download/${tournamentId}/template/${templateId}/blank`,accessToken);
    
    if(result.data === undefined) {
        return result.statusCode;
    }

    return { Filename: result.data.filename, Uri: result.data.value };
}

export const downloadSingleTournamentCredential = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId:string, templateId: string, studentId: string) : Promise<IFileDownload | number> => {
    var accessToken = await getToken([process.env.REACT_APP_PAYMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_PAYMENT_URI as string}credentials/download/${tournamentId}/template/${templateId}/student/${studentId}`,accessToken);
    
    if(result.data === undefined) {
        return result.statusCode;
    }

    return { Filename: result.data.filename, Uri: result.data.value };
}

export const downloadTournamentBracketsForDivision = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId:string, eventId: string, divisionId: string) : Promise<IFileDownload | number> => {
    var accessToken = await getToken([process.env.REACT_APP_PAYMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_PAYMENT_URI as string}brackets/download/${tournamentId}/event/${eventId}/division/${divisionId}`,accessToken);
    
    if(result.data === undefined) {
        return result.statusCode;
    }

    return { Filename: result.data.filename, Uri: result.data.value };
}

export const downloadTournamentBracketsForEvent = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId:string, eventId: string) : Promise<IFileDownload | number> => {
    var accessToken = await getToken([process.env.REACT_APP_PAYMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_PAYMENT_URI as string}brackets/download/${tournamentId}/event/${eventId}`,accessToken);
    
    if(result.data === undefined) {
        return result.statusCode;
    }

    return { Filename: result.data.filename, Uri: result.data.value };
}

export const downloadTournamentBrackets = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId:string) : Promise<IFileDownload | number> => {
    var accessToken = await getToken([process.env.REACT_APP_PAYMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_PAYMENT_URI as string}brackets/download/${tournamentId}`,accessToken);
    
    if(result.data === undefined) {
        return result.statusCode;
    }

    return { Filename: result.data.filename, Uri: result.data.value };
}

export const completeOnboarding = async (instance: IPublicClientApplication, account: AccountInfo, userToken:string) : Promise<boolean> => {
    var accessToken = await getToken([process.env.REACT_APP_PROVISIONING_API_SCOPES!],instance, account);
    var response = await getStatusCode(`${process.env.REACT_APP_PROVISIONING_URI as string}user/onboard/${userToken}`,accessToken);
    return response === 200;
}

export const getAllCurriculum = async (instance: IPublicClientApplication, account: AccountInfo) : Promise<CurriculumItem[]> => {
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_CURRICULUM_URI as string}curriculum/list/`,accessToken);
    var toReturn = new Array<CurriculumItem>();

    for(let d of result.data) {
       toReturn.push(buildCurriculumItem(d));
    }

    return toReturn;
}

export const getAllTournaments = async (instance: IPublicClientApplication, account: AccountInfo) : Promise<IAdminTournamentViewModel[]> => {
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_ENVIRONMENT_URI as string}tournament/list/`,accessToken);
    var toReturn : IAdminTournamentViewModel[] = [];

    for(let d of result.data) {
       toReturn.push({
        Id: d.id,
        Directors: d.directors,
        End: new Date(d.end),
        Start: new Date(d.start),
        Title: d.title,
        Type: d.type
       });
    }

    return toReturn;
}

const buildStudent = (data: any) : Student => {

    var customValues : ICustomValue[] = [];

    if(data.customValues !== null && data.customValues !== undefined) {
        for(let customValue of data.customValues) {
            customValues.push({
                Key: customValue.key,
                Value: customValue.value
            });
        }
    }

    var eventRegistrations: string[] = [];

    if (data.eventRegistrations !== null && data.eventRegistrations !== undefined) {
        for (let er of data.eventRegistrations) {
            eventRegistrations.push(er);
        }
    }

    return new Student({
        Id: data.id,
        FirstName: data.firstName,
        LastName: data.lastName,
        Gender: data.gender,
        DateOfBirth: new Date(data.dateOfBirth),
        ImageUri: data.imageUri,
        ProgramIds: data.programIds,
        Level: data.level !== undefined ? 
            new Level({
                Id: data.level.id,
                Description: data.level.description,
                Grade: data.level.grade,
                Order: data.level.order,
                Label: data.level.label,
                ProgramIds: data.level.programIds
            }) : undefined,
        LevelId: data.levelId,
        Status: data.status,
        PromotionHistory: [], //TODO: reimplement
        Weight: data.weight,
        Height: data.height,
        CustomValues: customValues,
        TrainingFacility: data.trainingFacility === undefined ? '' : data.trainingFacility,
        SpecialNeeds: data.specialNeeds,
        EventRegistrations: eventRegistrations
    });    
}

const getIntendedAudience = (jsonData : any) : Audience[] => {
    var intendedAudience :Audience[] = [];
    
    for(let a of jsonData) {
        intendedAudience.push(new Audience({
            LevelId: a.levelId,
            ProgramId: a.programId,
            Comparison: a.comparison
        }));
    }

    return intendedAudience;
}

const buildCurriculumItem = (d:any) : CurriculumItem => {

    var resources :Resource[] = [];

    if(d.resources !== undefined && d.resources !== null) {
        for (const r of d.resources) {
            resources.push(new Resource(
                {
                    Name:r.name,
                    Description: r.description,
                    MediaType: r.mediaType,
                    Uri: r.uri
                }));
        }
    }

    var toReturn = new CurriculumItem({
        Id: d.id,
        Description: d.description,
        ImageUri: d.imageUri,
        ResponseDescription: d.responseDescription === null ? undefined : d.responseDescription,
        Category: d.category,        
        IntendedAudience: getIntendedAudience(d.intendedAudience),
        Response: d.response,
        Resources: resources,
        Subcategory: d.subcategory,
        Name: d.name,
        Order: d.order,
        Steps: d.steps,
        Techniques: d.techniques,
        Type: d.type                           
    });

    return toReturn;
}

export const changeMembershipStatus = async (instance: IPublicClientApplication, account: AccountInfo, newStatus: MembershipStatus, membershipIds: string[]) : Promise<boolean> => {
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!],instance, account);
    var result = await postData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}membership/updatestatus/${newStatus}`,accessToken, membershipIds);
    
    if(result === undefined) {
        return false;
    }
    
    return result?.ok;
}

const buildUserProfile = (u: any): UserProfile => {
    var date = u.lastVisit === '0001-01-01T00:00:00' ? undefined : new Date(u.lastVisit);
    
    return new UserProfile({
        Id: u.id,
        DisplayName: u.displayName,
        Email: u.email,
        NotifyEmail: u.notifyEmail,
        Mobile: u.mobile,
        NotifyMobile: u.notifyMobile,
        MobileConsentDate: u.mobileConsentDate === undefined || u.mobileConsentDate === null ? undefined : new Date(u.mobileConsentDate),
        Issuer: u.issuer,
        ProfileImageUri: u.profileImageUri,
        Roles: u.roles,
        LastVisit: date,
        InvitationStatus: u.invitationStatus,
        Modified: new Date(u.modified)
    });
}

export interface IMembersResult {
    Memberships: Membership[];
    TotalMembers    : number;
    Offset: number;
}

export const findMember = async (instance: IPublicClientApplication, account: AccountInfo, search: string): Promise<Membership[]> => {
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}membership/find/${search}`, accessToken);
    
    var toReturn: Membership[] = [];
    
    for(let d of result.data) {
        var membership = new Membership();
        membership.Created = new Date(d.created);
        membership.Id = d.id;
        membership.Status = d.status as MembershipStatus;
        
        for(const s of d.students) {
            membership.Students.push(buildStudent(s));
        }

        for(const m of d.users) {            
            membership.Users!.push(buildUserProfile(m));
        }

        toReturn.push(membership);
    }

    return toReturn;
}

export const getMembers = async (instance: IPublicClientApplication, account: AccountInfo, skip: number, count: number) : Promise<IMembersResult> => {
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}membership/list/${count}?skip=${skip}`, accessToken);
    
    var toReturn : Membership[] = [];

    for(let d of result.data.memberships) {
        var membership = new Membership();
        membership.Created = new Date(d.created);
        membership.Modified = new Date(d.modified);
        membership.Invited = d.invited === '0001-01-01T00:00:00' ? undefined : new Date(d.invited);
        membership.Id = d.id;
        membership.Status = d.status as MembershipStatus;
        
        for(const s of d.students) {
            membership.Students.push(buildStudent(s));
        }

        for(const m of d.users) {            
            membership.Users!.push(buildUserProfile(m));
        }

        toReturn.push(membership);
    }

    return { Memberships: toReturn, Offset: result.data.offset, TotalMembers: result.data.totalMembers};
}

export const getAllLevels = async (instance: IPublicClientApplication, account: AccountInfo) : Promise<Level[]> => {
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}levels/list/`,accessToken);

    var toReturn : Level[] = [];
    
    for(let l of result.data) {
        toReturn.push(new Level({
            Description: l.description,
            Grade: l.grade,
            Label: l.label,
            Id: l.id,
            ProgramIds: l.programIds,
            Order: l.order
        }));
    }
    
    return toReturn;
}

export const getAllPrograms = async (instance: IPublicClientApplication, account: AccountInfo) : Promise<Program[]> => {
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}programs/list/`,accessToken);

    var toReturn : Program[] = [];
    
    for(let l of result.data) {
        toReturn.push(new Program({
            Id: l.id,
            Name: l.name
        }));
    }

    return toReturn;
}

export const getAvailableTimezones = async (instance: IPublicClientApplication, account: AccountInfo) : Promise<ITimezoneViewModel[]> => {
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_ENVIRONMENT_URI as string}timezones/list`,accessToken);

    var toReturn : ITimezoneViewModel[] = [];

    for(let d of result.data) {
        toReturn.push({
            Id: d.id,
            DisplayName: d.displayName
        });
    }

    return toReturn;
}

export const provisionMobilePhone = async (instance: IPublicClientApplication, account: AccountInfo, mobilePhone: string): Promise<boolean> => {
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!], instance, account);
    var result = await postData(
        `${process.env.REACT_APP_ENVIRONMENT_URI as string}environment/user/mobile/provision`,
        accessToken,
        { number: mobilePhone }
    );
    
    return result === undefined ? false : result.ok;
}

export const confirmMobilePhone = async (instance: IPublicClientApplication, account: AccountInfo, confirmationCode: string): Promise<boolean> => {
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!], instance, account);
    var result = await postData(
        `${process.env.REACT_APP_ENVIRONMENT_URI as string}environment/user/mobile/confirm`,
        accessToken,
        { code: confirmationCode }
    );
    
    return result === undefined ? false : result.ok;
}

export const updateEmailNotificationPreference = async (instance: IPublicClientApplication, account: AccountInfo, enabled: boolean): Promise<boolean> => {
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!],instance, account);
    var result = await getStatusCode(`${process.env.REACT_APP_ENVIRONMENT_URI as string}environment/user/notify/email/${enabled}`,accessToken);

    return result === 200;
}

export const updateMobileNotificationPreference = async (instance: IPublicClientApplication, account: AccountInfo, enabled: boolean): Promise<boolean> => {
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!],instance, account);
    var result = await getStatusCode(`${process.env.REACT_APP_ENVIRONMENT_URI as string}environment/user/notify/mobile/${enabled}`,accessToken);

    return result === 200;
}

export const updateUserProfile = async (instance: IPublicClientApplication, account: AccountInfo, userProfile: UserProfile, trainingFacilityId?: string, trainingFacilityTitle?: string): Promise<boolean> => {
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!],instance, account);
    var result = await postData(
        `${process.env.REACT_APP_ENVIRONMENT_URI as string}environment/user/update`,
        accessToken, {
            DisplayName: userProfile.DisplayName,
            Email: userProfile.Email,
            Mobile: userProfile.Mobile,
            TrainingFacilityId: trainingFacilityId,
            TrainingFacilityTitle: trainingFacilityTitle
        });

    return result === undefined ? false : result.ok;
}

export const getUserProfileImage = async (instance: IPublicClientApplication, account: AccountInfo, userId: string) : Promise<string> => {
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_ENVIRONMENT_URI as string}environment/user/${userId}/photo`,accessToken);

    return result.data.profileImageUri;
}

export const getTenantEnvironment = async (instance: IPublicClientApplication, account: AccountInfo) : Promise<TenantEnvironment> => {
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_ENVIRONMENT_URI as string}environment/`,accessToken);

    return new TenantEnvironment({
        Id: result.data.id,
        Title: result.data.title,
        Features: result.data.features,
        StorageQuota: result.data.storageQuota,
        StorageQuotaUsed: result.data.storageQuotaUsed,
        PaymentGatewayPublicToken: result.data.paymentGatewayPublicToken
    });
}

const buildInputAndOptions = (i: any) : IUserInput => {
    var options: IUserInputOption[] = [];
                    
    if(i.options !== null) {
        for(let o of i.options) {
            options.push({
                Id: o.id,
                Label: o.label
            });
        }
    }

    return {
        BindTo: i.bindTo,
        IsRequired: i.isRequired,
        Label: i.label,
        Description: i.description,
        Type: i.type,
        Options: options,
        DataValidator: i.dataValidator
    };
}

const buildTournamentEvents = (data: any): TournamentEvent[] => {
    var events: TournamentEvent[] = [];
    
    for(let e of data) {
        var eventInputs : IUserInput[] = [];

        if(e.inputs !== null) {
            for(let eventInput of e.inputs) {
                eventInputs.push(buildInputAndOptions(eventInput));
            }
        }

        let prereqs: IPurchasePrerequisite[] = [];
        
        for (var p of e.prerequisites) {
            prereqs.push({
                CatalogIds: p.catalogIds,
                EventIds: p.eventIds,
                Type: p.type
            });
        }

        let studentRegistrationViewModels: IStudentRegistration[] = [];

        if (e.students !== null) {
            for (let s of e.students) {
                studentRegistrationViewModels.push({
                    StudentId: s.studentId,
                    CanRegister: s.canRegister
                });
            }    
        }

        events.push(new TournamentEvent(
            e.order,
            e.status,
            e.role,
            e.curriculumId,
            e.title,
            e.description,
            e.subcategory,            
            e.imageUri,
            studentRegistrationViewModels,  
            eventInputs,
            e.allRegisteredStudentIds,
            e.bracketingPolicy,
            e.additionalTerms,
            prereqs,
            e.purchaseGroupId
        ));
    }

    return events;
}

const buildTournamentCatalog = (data: any): ICatalogItemWithQuantity[] => {
    var catalog: ICatalogItemWithQuantity[] = [];
    
    if(data !== null && data !== undefined) {
        for(let c of data) {
            var inputs: IUserInput[] = [];

            if(c.catalogItem.inputs !== null && c.catalogItem.inputs !== undefined) {
                for(let i of c.catalogItem.inputs) {
                    inputs.push(buildInputAndOptions(i));
                }
            }
            
            catalog.push({
                    Description: c.catalogItem.description,
                    Id: c.catalogItem.id,
                    CatalogItemId: c.catalogItem.id, 
                    ImageUri: c.catalogItem.imageUri,
                    Inputs: inputs,
                    IsSuggested: c.catalogItem.isSuggested,
                    IsTaxed: c.catalogItem.isTaxed,
                    Name: c.catalogItem.name,
                    Price: c.catalogItem.price,
                    AvailableQuantity: c.availableQuantity,
                    AdditionalTerms: c.additionalTerms
                }
            );
        }
    }
    
    return catalog;
}

const buildTournament = (data:any) : Tournament => { 
    var catalog = buildTournamentCatalog(data.catalog);
    var events = buildTournamentEvents(data.events);

    var students :Student[] = [];

    if(data.students !== undefined) {
        for(let s of data.students) {
            students.push(buildStudent(s));
        }
    }

    var pricing: IRegistrationOption[] = [];

    for(let p of data.pricing) {
        pricing.push({
            CurriculumId: p.curriculumId,
            PricingStrategyId: p.pricingStrategyId,
            Price: p.price,
            Role: p.role,
            AdditionalFee: (p.additionalFee === null || p.additionalFee === undefined) ?
                0 : p.additionalFee
        });
    }

    var pricingStrategies : IPricingStrategy[] = [];

    if(data.pricingStrategies !== null) {
        for(let ps of data.pricingStrategies) {
            pricingStrategies.push({
                Id: ps.id,
                InitialPrice: ps.initialPrice,
                SubsequentPrice: ps.subsequentPrice,
                Expiry: new Date(ps.expiry),
                Title: ps.title
            });
        }
    }

    var purchaseGroups: IPurchaseGroup[] = [];

    if(data.purchaseGroups !== null) {
        for(let pg of data.purchaseGroups) {
            purchaseGroups.push({
                Id: pg.id,
                Title: pg.title,
                Subtitle: pg.subtitle,
                MaxItemsPerParticipant: pg.maxItemsPerParticipant,
                MinItemsPerParticipant: pg.minItemsPerParticipant,
                ItemFilter: pg.itemFilter
            });
        }
    }

    var rawPricingStrategies: IPricingStrategy[] = [];
    
    if (data.rawPricingStrategies !== null) {
        for(let ps of data.rawPricingStrategies) {
            rawPricingStrategies.push({
                Id: ps.id,
                InitialPrice: ps.initialPrice,
                SubsequentPrice: ps.subsequentPrice,
                Expiry: new Date(ps.expiry),
                Title: ps.title
            });
        }
    }

    var stakeholders : ITournamentStakeholder[] = [];

    if(data.stakeholders !== undefined && data.stakeholders !== null) {
        for(let stakeholder of data.stakeholders) {
            var stakeholderRoles: ITournamentStakeholderRole[] = [];

            for(let role of stakeholder.roles) {
                stakeholderRoles.push({
                    StudentIds: role.studentIds,
                    Value: role.value
                });
            }
            
            stakeholders.push({
                MembershipId: stakeholder.membershipId,
                Roles: stakeholderRoles
            })
        }
    }

    var credentialTemplates: ITournamentCredentialTemplate[] = [];

    if (data.credentialTemplates !== undefined && data.credentialTemplates !== null) {
        for(let credentialTemplate of data.credentialTemplates) {
            credentialTemplates.push({
                Id: credentialTemplate.id,
                DisplayName: credentialTemplate.displayName
            });
        }
    }
    
    return new Tournament({
        Id: data.id,
        Title: data.title,
        CredentialTemplates: credentialTemplates,
        Created: new Date(data.created),
        EndDate: new Date(data.endDate),
        Location: data.location,
        Type: data.type,
        DisplayAfter: data.displayAfter !== undefined ? new Date(data.displayAfter) : undefined,
        RegistrationEnds: data.registrationEnds !== null ? new Date(data.registrationEnds) : undefined,
        Description: data.description,
        EventLandingPage: data.eventLandingPage,
        RegistrationDescription: data.registrationDescription,
        ImageUri: data.imageUri,
        Pricing: pricing,
        PricingStrategies: pricingStrategies,
        PurchaseGroups: purchaseGroups,
        RawPricingStrategies: rawPricingStrategies,
        StartDate: new Date(data.startDate),
        Events: events,
        Catalog: catalog,
        Students: students,
        Terms: data.terms,
        JudgingTerms: data.judgingTerms,
        Stakeholders: stakeholders,
        Layout: data.layout,
        StatementDescriptor: data.statementDescriptor,
        BracketDownloadUri: data.bracketDownloadUri,
        HasEnded: data.hasEnded,
        StatusHubId: data.statusHubId,
        Timezone: data.timezone,
        AthleteCallMinutes: data.athleteCallMinutes,
        BracketLocations: data.bracketLocations,
        StagingLocations: data.stagingLocations,
        CustomerPaysConvenienceFee: data.customerPaysConvenienceFee,
        ConvenienceFeeDescriptor: data.convenienceFeeDescriptor,
        SupportEmail: data.supportEmail,
        ReceiptImageUri: data.receiptImageUri,
        DivisionRulesModified: data.divisionRulesModified === null ? undefined : new Date(data.divisionRulesModified)
    });
}

export const getTournamentLeaderboard = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string) : Promise<TournamentLeaderboard> => {
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_ENVIRONMENT_URI as string}tournament/id/${tournamentId}/leaderboard`,accessToken);

    var rows = new Array<ILeaderboardRow>();
    
    for(let r of result.data.rows) {
        rows.push({
            EventId: r.eventId,
            EventName: r.eventName,
            DivisionName: r.divisionName,
            BracketName: r.bracketName,
            Place: r.place,
            Score: r.score,
            StudentCurriculumSubmissionId: r.studentCurriculumSubmissionId,
            StudentLevel: new Level({
                Id: r.studentLevel.id,
                Description: r.studentLevel.description,
                Grade: r.studentLevel.grade,
                Label: r.studentLevel.label,
                Order: r.studentLevel.order,
                ProgramIds: r.studentLevel.programIds
            }),
            StudentFirstName: r.studentFirstName,
            StudentLastName: r.studentLastName,
            StudentImage: r.studentImage,
            IsHighlighted: r.isHighlighted
        });
    }

    var status : IBracketStatus[] = [];

    for(let s of result.data.status) {
        status.push({
            IsProvisional: s.isProvisional,
            Name: s.name
        });
    }

    var leaderboard = new TournamentLeaderboard({
        TournamentImageUri: result.data.tournamentImageUri,
        TournamentTitle: result.data.tournamentTitle,
        Rows: rows,
        Status: status
    });

    return leaderboard;
}

export const getTournamentForEvent = async (instance: IPublicClientApplication, account: AccountInfo, eventId: string) : Promise<Tournament | undefined> => {
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_ENVIRONMENT_URI as string}tournament/event/${eventId}`,accessToken);

    if(result.data === undefined) {
        return undefined;
    }
    
    return buildTournament(result.data);
}

export const getTournament = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string) : Promise<Tournament> => {
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_ENVIRONMENT_URI as string}tournament/id/${tournamentId}`,accessToken);

    return buildTournament(result.data);
}

export const getTournamentCredentialTemplates = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string) : Promise<ITournamentCredentialTemplate[]> => {
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_ENVIRONMENT_URI as string}tournament/id/${tournamentId}/credentialtemplates`,accessToken);

    let credentialTemplates: ITournamentCredentialTemplate[] = [];
    
    for (let c of result.data) {
        credentialTemplates.push({
            Id: c.id,
            DisplayName: c.displayName
        });
    }

    return credentialTemplates;
}

export const getNextTournament = async (instance: IPublicClientApplication, account: AccountInfo) : Promise<Tournament | undefined> => {
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_ENVIRONMENT_URI as string}tournament/next`,accessToken);

    //likely this was a 404 which means no tournament is coming up
    if(result.data === undefined) {
        return undefined;
    }

    return buildTournament(result.data);
}

export const getRecentTournaments = async (instance: IPublicClientApplication, account: AccountInfo, max: number = 5) : Promise<Tournament[] | undefined> => {
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_ENVIRONMENT_URI as string}tournament/recent/${max}`,accessToken);

    //likely this was a 404 which means no tournaments in the system at all
    if(result.data === undefined) {
        return undefined;
    }

    var toReturn : Tournament[] = [];

    for(let d of result.data) {
        toReturn.push(buildTournament(d));
    }

    return toReturn;
}

export const getPreviousTournament = async (instance: IPublicClientApplication, account: AccountInfo) : Promise<Tournament | undefined> => {
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_ENVIRONMENT_URI as string}tournament/previous`,accessToken);

    //likely this was a 404 which means no tournaments in the system at all
    if(result.data === undefined) {
        return undefined;
    }

    return buildTournament(result.data);
}

export interface ICouponResult {
    AmountOwing: number;
    Discount: number;
    ConvenienceFee: number;

    IsValid: boolean;
}

export const applyCoupon = async (instance: IPublicClientApplication, account: AccountInfo, transactionId: string, coupon: string) : Promise<ICouponResult> => {
    var accessToken = await getToken([process.env.REACT_APP_PAYMENT_API_SCOPES!],instance, account);
    var response = await getData(`${process.env.REACT_APP_PAYMENT_URI as string}transaction/${transactionId}/apply/${coupon}`,accessToken);
 
    if(response.statusCode !== 200) {
        return {
            IsValid: false,
            AmountOwing: 0,
            Discount: 0,
            ConvenienceFee: 0
        }; //invalid coupon
    }

    return {
        AmountOwing: response.data.amountOwing,
        Discount: response.data.discount,
        ConvenienceFee: response.data.convenienceFee,
        IsValid: true
    };
}

export enum CouponType {
    DiscountAmount = 0
}

export interface ICoupon {
    Id: string;
    ActualRedemptions: number;
    MaxRedemptions?: number;
    MaxRedemptionsPerMembership?: number;
    Value: number;
    Expiry?: Date;
    TournamentId: string;
    Type: CouponType;
}

export const createCoupon = async (instance: IPublicClientApplication, account: AccountInfo, coupon: ICoupon) : Promise<ICoupon | undefined> => {    
    var accessToken = await getToken([process.env.REACT_APP_PAYMENT_API_SCOPES!], instance, account);
    var response = await postData(`${process.env.REACT_APP_PAYMENT_URI as string}coupons/${coupon.TournamentId}/add`,accessToken, coupon);
    
    if(response?.ok) {
        var data = await response.json();

        return {
            ActualRedemptions: 0,
            TournamentId: data.tournamentId,
            Expiry: new Date(data.expiry),
            Id: data.id,
            MaxRedemptions: data.maxRedemptions,
            Type: data.type,
            Value: data.value,
            MaxRedemptionsPerMembership: data.maxRedemptionsPerMembership
        } as ICoupon;
    }
    
    return undefined;
}

export const getCoupons = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string): Promise<ICoupon[]> => {
    var accessToken = await getToken([process.env.REACT_APP_PAYMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_PAYMENT_URI as string}coupons/${tournamentId}`, accessToken);
    
    var toReturn: ICoupon[] = [];
    
    for(let c of result.data) {
        toReturn.push({
            Id: c.id,
            ActualRedemptions: c.actualRedemptions,
            MaxRedemptions: c.maxRedemptions === null ? undefined : c.maxRedemptions,
            MaxRedemptionsPerMembership: c.maxRedemptionsPerMembership === null ? undefined : c.maxRedemptionsPerMembership,
            Value: c.value,
            Expiry: c.expiry === null || c.expiry === undefined ? undefined : new Date(c.expiry),
            TournamentId: c.tournamentId,
            Type: c.type
        });
    }

    return toReturn;
}

export interface ICompletedTransaction {
    Transaction: Transaction;
    ReceiptAdditionalContent: string;
}

export const completePayment = async (instance: IPublicClientApplication, account: AccountInfo, clientSecret: string) : Promise<ICompletedTransaction> => {
    var accessToken = await getToken([process.env.REACT_APP_PAYMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_PAYMENT_URI as string}transactions/complete/${clientSecret}`,accessToken);
    
    return {
        Transaction: buildTransaction(result.data.transaction),
        ReceiptAdditionalContent: result.data.receiptAdditionalContent
    }
}

export interface IPaymentSetupResponse {
    ClientSecret: string;
    ConvenienceFee: number;
    NeedsPayment: boolean;
    TransactionId: string;
    LineItems: ILineItem[];
}

export const setupInvoicePayment = async (instance: IPublicClientApplication, account: AccountInfo, invoiceId: string) : Promise<IPaymentSetupResponse | undefined> => {
    var accessToken = await getToken([process.env.REACT_APP_PAYMENT_API_SCOPES!],instance, account);
    var response = await getData(`${process.env.REACT_APP_PAYMENT_URI as string}transactions/setup/invoice/${invoiceId}`,accessToken);

    if(response?.statusCode !== 200) {
        return undefined;
    }
    
    let lineItems : ILineItem[] = [];
    
    for(let d of response.data.lineItems) {
        lineItems.push({
            CatalogItemId: d.catalogItemId,
            InvoiceId: d.invoiceId,
            StudentId: d.studentId,
            Description: d.description,
            EventId: d.eventId,
            Price: d.price,
            Tax: d.tax
        });
    }
    return { 
        ClientSecret: response.data.clientSecret, 
        NeedsPayment: response.data.needsPayment,
        ConvenienceFee: response.data.convenienceFee,
        TransactionId: response.data.transactionId,
        LineItems: lineItems
    };
}

export const setupTournamentPayment = async (instance: IPublicClientApplication, account: AccountInfo, pendingRegistration: TournamentRegistration) : Promise<IPaymentSetupResponse | undefined> => {
    var accessToken = await getToken([process.env.REACT_APP_PAYMENT_API_SCOPES!],instance, account);
    var response = await postData(`${process.env.REACT_APP_PAYMENT_URI as string}transactions/setup/tournament`,accessToken, pendingRegistration);

    if(!response?.ok) {
        return undefined;
    }

    var result = await response?.json();
    
    let lineItems : ILineItem[] = [];
    
    for(let d of result.lineItems) {
        lineItems.push({
            CatalogItemId: d.catalogItemId,
            InvoiceId: d.invoiceId,
            StudentId: d.studentId,
            Description: d.description,
            EventId: d.eventId,
            Price: d.price,
            Tax: d.tax
        });
    }
    return { 
        ClientSecret: result.clientSecret, 
        NeedsPayment: result.needsPayment,
        ConvenienceFee: result.convenienceFee,
        TransactionId: result.transactionId,
        LineItems: lineItems
    };
}

export interface ILineItemCancelation {
    catalogItemId?: string;
    eventId?: string;
    studentId?: string;
    qty?: number;
}

interface IRefundRequest {
    description: string;
    amount: number;
    lineItemsToCancel: ILineItemCancelation[];
}

export const refundTransaction = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string, transactionId: string, refundRequest: IRefundRequest)  : Promise<boolean | undefined> => {
    var accessToken = await getToken([process.env.REACT_APP_PAYMENT_API_SCOPES!],instance, account);
    var response = await postData(`${process.env.REACT_APP_PAYMENT_URI as string}transactions/manage/${tournamentId}/refund/${transactionId}`,accessToken, refundRequest);

    if(!response?.ok) {
        return false;
    }

    return true;
}

export const cancelRegistration = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string, registrationId: string)  : Promise<boolean | undefined> => {
    var accessToken = await getToken([process.env.REACT_APP_PAYMENT_API_SCOPES!],instance, account);
    var response = await getStatusCode(`${process.env.REACT_APP_PAYMENT_URI as string}registrations/manage/${tournamentId}/cancel/${registrationId}`,accessToken);

    return response === 200;
}

const buildTransaction = (t: any) : Transaction => {
    let lineItems : ILineItem[] = [];

    for(let l of t.lineItems) {
        lineItems.push({
            CatalogItemId: l.catalogItemId,
            EventId: l.eventId,
            InvoiceId: l.invoiceId,
            StudentId: l.studentId,
            Description: l.description,
            Price: l.price,
            Tax: l.tax
        });
    }

    return new Transaction({
        Id: t.id,
        Date: new Date(t.date),
        Discount: t.discount,
        Method: t.method,
        CardBrand: t.cardBrand,
        Category: t.category,
        DeclineCode: t.declineCode,
        ErrorCode: t.errorCode,
        ErrorDescription: t.errorDescription,
        ErrorType: t.errorType,
        LastFour: t.lastFour,
        LineItems: lineItems,
        Status: t.status,
        Type: t.type,
        UserId: t.userId,
        ConvenienceFee: t.convenienceFee
    });
}

export const getTransactionsById  = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string, transactionIds: string[]) : Promise<Transaction[] | number> => {
    var accessToken = await getToken([process.env.REACT_APP_PAYMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_PAYMENT_URI as string}transactions/manage/${tournamentId}/get/${transactionIds.join(',')}`,accessToken);

    if(result.data === undefined) {
        return result.statusCode;
    }

    var toReturn : Transaction[] = [];

    for(let t of result.data) {
        toReturn.push(buildTransaction(t));
    }
    
    return toReturn;
}

export const getInvoicesForTournament = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string) : Promise<IInvoicesViewModel | number> => {
    var accessToken = await getToken([process.env.REACT_APP_PAYMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_PAYMENT_URI as string}invoices/manage/${tournamentId}`,accessToken);

    if(result.data === undefined) {
        return result.statusCode;
    }
    
    let invoices: IInvoice[] = [];
    let users: UserProfile[] = [];

    for(let i of result.data.invoices) {
        invoices.push(buildInvoice(i));
    }

    for(let u of result.data.users) {
        users.push(buildUserProfile(u));
    }

    return {
        Invoices: invoices,
        Users: users
    };
}

export const getTransactionsForTournament = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string) : Promise<TransactionViewModel | number> => {
    var accessToken = await getToken([process.env.REACT_APP_PAYMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_PAYMENT_URI as string}transactions/manage/${tournamentId}`,accessToken);

    if(result.data === undefined) {
        return result.statusCode;
    }

    var vm = new TransactionViewModel();

    for(let t of result.data.transactions) {        
        vm.Transactions.push(buildTransaction(t));
    }

    for(let u of result.data.users) {
        vm.Users.push(buildUserProfile(u));
    }

    return vm;
}

export const getAllTransactions = async (instance: IPublicClientApplication, account: AccountInfo) : Promise<TransactionViewModel | number> => {
    var accessToken = await getToken([process.env.REACT_APP_PAYMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_PAYMENT_URI as string}transactions/all`,accessToken);

    if(result.data === undefined) {
        return result.statusCode;
    }

    var vm = new TransactionViewModel();

    for(let t of result.data.transactions) {        
        vm.Transactions.push(buildTransaction(t));
    }

    for(let u of result.data.users) {
        vm.Users.push(buildUserProfile(u));
    }

    return vm;
}

export interface IStakeholderViewModel {
    Email: string;
    MembershipId: string;
    StudentId: string;
    DisplayName: string;
    Role: string;
    TrainingFacility: string;
}



export const addStakeholderToTournament = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string, stakeholder: IStakeholderViewModel) : Promise<boolean> => {
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!],instance, account);
    var response = await postData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}stakeholders/${tournamentId}/add`, accessToken, stakeholder);

    if(response === undefined) {
        return false;
    }

    return response.ok;
}

const buildStakeholder = (data: any): IStakeholderViewModel => {
    return {
        Email: data.email,
        MembershipId: data.membershipId,
        StudentId: data.studentId,
        DisplayName: data.displayName,
        Role: data.role,
        TrainingFacility: data.trainingFacility
    };
}

export const getStakeholdersForTournament = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string) : Promise<IStakeholderViewModel[] | number> => {
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}stakeholders/${tournamentId}`,accessToken);
    
    if(result.data === undefined) {
        return result.statusCode;
    }

    var toReturn : IStakeholderViewModel[] = [];

    for(let d of result.data) {
        toReturn.push(buildStakeholder(d));
    }

    return toReturn;
}

export const findStudentByName = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string, studentName: string) : Promise<IStakeholderViewModel[] | number> => {
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}students/${tournamentId}/find/${studentName}`,accessToken);
    
    if(result.data === undefined) {
        return result.statusCode;
    }

    var toReturn : IStakeholderViewModel[] = [];

    for(let d of result.data) {
        toReturn.push(buildStakeholder(d));
    }

    return toReturn;
}

export const getRegistrationsForTournament = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string) : Promise<TournamentRegistrationViewModel | number> => {
    var accessToken = await getToken([process.env.REACT_APP_PAYMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_PAYMENT_URI as string}registrations/manage/${tournamentId}`,accessToken);
    
    if(result.data === undefined) {
        return result.statusCode;
    }

    var vm = new TournamentRegistrationViewModel();
    
    for(let c of result.data.curriculum) {
        vm.Curriculum.push(buildCurriculumItem(c));
    }

    for(let a of result.data.catalog) {
        vm.Catalog.push(buildCatalogItem(a));
    }

    for(let t of result.data.tournaments) {
        vm.Tournaments.push(buildTournament(t));
    }

    for(let s of result.data.students) {
        vm.Students.push(buildStudent(s));
    }

    for(let r of result.data.registrations) {
        vm.Registrations.push(buildTournamentRegistration(r));
    }

    for(let u of result.data.users) {
        vm.Users.push(buildUserProfile(u));
    }

    return vm;
}

const buildCatalogItem = (data: any) : CatalogItem => {
    var inputs : IUserInput[] = [];

    for(let i of data.inputs) {
        inputs.push({
             BindTo: i.bindTo,
             IsRequired: i.isRequired,
             Label: i.label,
             Description: i.description,
             Options: i.options,
             Type: i.type
        });
    }

    return new CatalogItem({
        Description: data.description,
        Id: data.id,
        ImageUri: data.imageUri,
        IsSuggested: data.isSuggested,
        IsTaxed: data.isTaxed,
        Name: data.name,
        Inputs: inputs,
        Price: data.price
    });
}

export const getAllTournamentRegistrations = async (instance: IPublicClientApplication, account: AccountInfo) : Promise<TournamentRegistrationViewModel | number> => {
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_ENVIRONMENT_URI as string}tournament/registrations`,accessToken);

    if(result.data === undefined) {
        return result.statusCode;
    }

    var vm = new TournamentRegistrationViewModel();
    
    for(let i of result.data.catalog) {
        vm.Catalog.push(buildCatalogItem(i));
    }

    for(let c of result.data.curriculum) {
        vm.Curriculum.push(buildCurriculumItem(c));
    }

    for(let t of result.data.tournaments) {
        vm.Tournaments.push(buildTournament(t));
    }

    for(let s of result.data.students) {
        vm.Students.push(buildStudent(s));
    }

    for(let r of result.data.registrations) {
        vm.Registrations.push(buildTournamentRegistration(r));
    }

    for(let u of result.data.users) {
        vm.Users.push(buildUserProfile(u));
    }

    return vm;
}

export const getRegisteredStudentsForTournament = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string) : Promise<Student[] | undefined> => {
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_ENVIRONMENT_URI as string}tournament/id/${tournamentId}/students`,accessToken);
    
    if(result.data === undefined) {
        return undefined;
    }

    var toReturn : Student[] = [];
    
    for(let d of result.data) {        
        toReturn.push(buildStudent(d));
    }

    return toReturn;
}

export const getRegisteredStudentsForEvent = async (instance: IPublicClientApplication, account: AccountInfo, tournamentEventId: string) : Promise<Student[] | undefined> => {
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_ENVIRONMENT_URI as string}tournament/event/${tournamentEventId}/students`,accessToken);
    
    if(result.data === undefined) {
        return undefined;
    }

    var toReturn : Student[] = [];
    
    for(let d of result.data) {        
        toReturn.push(buildStudent(d));
    }

    return toReturn;
}
export const getAllTournamentEntries = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string, retrieveVideo: boolean = false) : Promise<TournamentEntryViewModel[] | undefined> => {
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_ENVIRONMENT_URI as string}tournament/id/${tournamentId}/entries?video=${retrieveVideo ? '1' : '0'}`,accessToken);
    
    if(result.data === undefined) {
        return undefined;
    }

    var toReturn : TournamentEntryViewModel[] = [];
    
    for(let d of result.data) {

        var submission = buildSubmission(d.submission);
        var student = buildStudent(d.student);

        toReturn.push(new TournamentEntryViewModel(
            d.views,
            d.whoReacted,
            submission,
            student    
        ));
    }

    return toReturn;
}

const buildSubmission = (submission: any) : StudentCurriculumSubmission => {
    return new StudentCurriculumSubmission({
        Id: submission.id,
        StudentId: submission.studentId,
        StudentLevelIdWhenSubmitted: submission.studentLevelIdWhenSubmitted,
        StudentLevelWhenSubmitted: {...new Level()},
        MembershipId: submission.membershipId,
        PosterImageUri: submission.posterImageUri,
        ContentType: submission.contentType,
        ContentUri: submission.contentUri,
        CurriculumId: submission.curriculumId,
        Created: submission.created,
        Status: submission.status 
    });
}

export const getTournamentEntries = async (instance: IPublicClientApplication, account: AccountInfo, tournamentEventId: string, retrieveVideo: boolean = false) : Promise<TournamentEntryViewModel[] | undefined> => {
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_ENVIRONMENT_URI as string}tournament/event/${tournamentEventId}/entries?video=${retrieveVideo ? '1' : '0'}`,accessToken);
    
    if(result.data === undefined) {
        return undefined;
    }

    var toReturn : TournamentEntryViewModel[] = [];
    
    for(let d of result.data) {

        var submission = buildSubmission(d.submission);
        var student = buildStudent(d.student);

        toReturn.push(new TournamentEntryViewModel(
            d.views,
            d.whoReacted,
            submission,
            student    
        ));
    }

    return toReturn;
}

export const getTournamentRegistrations = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string) : Promise<TournamentRegistration[] | undefined> => {
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}membership/tournament/${tournamentId}/registration`,accessToken);

    //likely this was a 404 which means no registration was found
    if(result.data === undefined) {
        return undefined;
    }

    var toReturn : TournamentRegistration[] = [];
    
    for(let rego of result.data) {
        toReturn.push(buildTournamentRegistration(rego));
    }

    return toReturn;
}

export const getRegistrationsForMember = async (instance: IPublicClientApplication, account: AccountInfo) : Promise<IRegistrationHistoryViewModel[] | undefined> => {
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}membership/registrations`,accessToken);

    if (result.statusCode === 404) {
        return undefined;    
    }

    var toReturn : IRegistrationHistoryViewModel[] = [];

    for (let vm of result.data) {
        
        let registrations: TournamentRegistration[] = [];

        for (var rego of vm.registrations) {
            registrations.push(buildTournamentRegistration(rego));    
        }

        let students: Student[] = [];

        for (var s of vm.students) {
            students.push(buildStudent(s));
        }
        
        toReturn.push({
            BracketDownloadUri: vm.bracketDownloadUri,            
            EndDate: new Date(vm.endDate),
            StartDate: new Date(vm.startDate),
            Catalog: buildTournamentCatalog(vm.catalog),
            Events: buildTournamentEvents(vm.events),
            HasEnded: vm.hasEnded,
            ImageUri: vm.imageUri,
            Location: vm.location,
            Registrations: registrations,
            Reviews: [],
            Students: students,
            StatusHubId: vm.statusHubId,
            Title: vm.title,
            TournamentId:vm.tournamentId
        });
    }

    return toReturn;
}

const buildTournamentRegistration = (data: any) : TournamentRegistration => {
    var events : ITournamentEventRegistration[] = [];

    if(data.events !== undefined && data.events !== null) {
        for(let e of data.events) {
            events.push({
                Created: new Date(e.created),
                CurriculumId: e.curriculumId,
                SubmissionId: e.submissionId,
                Id: e.id,
                Role: e.role,
                StudentId: e.studentId,
                Status: e.status           
            });
        }
    }

    var catalog : ITournamentCatalogItemPurchase[] = [];

    if(data.catalog !== undefined && data.catalog !== null) {
        for(let c of data.catalog) {
            var config: ITournamentCatalogItemConfig[] = [];

            if(c.config !== undefined && c.config !== null) {
                for(let cf of c.config) {
                    config.push({
                        Key: cf.key,
                        Value: cf.value
                    })
                }
            }
            
            catalog.push({
                CatalogItemId: c.catalogItemId,
                Qty: c.qty,
                Config: config,
                Status: c.status,
                Created: new Date(c.created)
            });
        }
    }
    
    return new TournamentRegistration({
        Id: data.id,
        TournamentId: data.tournamentId,
        Created: new Date(data.created),
        Modified: new Date(data.modified),
        Catalog: catalog,
        Events: events,
        UserId: data.userId,
        Status: data.status,
        TransactionIds: data.transactionIds
    });
}

export const getProvisionUser = async (token: string, displayName: string): Promise<ProvisionResult> => {
    var result = await getData(`${process.env.REACT_APP_PROVISIONING_URI as string}registration/provision/${token}/${displayName}`);

    return new ProvisionResult({
        Uri: result.data.uri,
        Status: result.data.status
    });
}

export const getIsMemberFound = async (email: string): Promise<boolean> => {
    var statusCode = await getStatusCode(`${process.env.REACT_APP_MEMBERSHIP_URI as string}registration/checkmember/${email}`);

    if(statusCode === 200) {
        return true;
    }

    return false;
}

export const getProvisionPreview = async (token: string): Promise<ProvisionPreview> => {
    var result = await getData(`${process.env.REACT_APP_PROVISIONING_URI as string}registration/provision/${token}/preview`);

    return new ProvisionPreview(
        result.data.email,
        result.data.displayName        
    );
}

export const getUserProfile = async (instance: IPublicClientApplication, account: AccountInfo): Promise<UserProfile> => {
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_ENVIRONMENT_URI as string}environment/me`,accessToken);

    return buildUserProfile(result.data);
}

export const getTournamentRoles = async (instance: IPublicClientApplication, account: AccountInfo): Promise<ITournamentStakeholderRoleWithId[]> => {
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!],instance, account);
    var result = await getData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}membership/tournament/roles`,accessToken);

    var toReturn : ITournamentStakeholderRoleWithId[] = [];

    if(result.data !== undefined) {
        for(let d of result.data) {
            toReturn.push({
                StudentIds: d.studentIds,
                Title: d.title,
                TournamentId: d.tournamentId,
                Value: d.value
            })
        }
    }
    return toReturn;
}

export const getAllReviewsByTournamentId = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string) : Promise<ReviewCollection[] | undefined> => {
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!],instance,account);
    var result = await getData(`${process.env.REACT_APP_CURRICULUM_URI as string}review/all/tournament/${tournamentId}`,accessToken);
    
    if(result.data === undefined) {
        return undefined;
    }

    var toReturn : ReviewCollection[] = [];

    for(let r of result.data.reviews) {
        toReturn.push(buildReviewCollection(r));
    }
    
    return toReturn;
}

export const getReviewBySubmissionId = async (instance: IPublicClientApplication, account: AccountInfo, submissionId: string) : Promise<ReviewCollection | undefined> => {
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!],instance,account);
    var result = await getData(`${process.env.REACT_APP_CURRICULUM_URI as string}review/submission/id/${submissionId}`,accessToken);
    
    if(result.data === undefined) {
        return undefined;
    }
    
    return buildReviewCollection(result.data);
}

export const getReviewById = async (instance: IPublicClientApplication, account: AccountInfo, reviewId: string) : Promise<ReviewCollection | undefined> => {
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!],instance,account);
    var result = await getData(`${process.env.REACT_APP_CURRICULUM_URI as string}review/id/${reviewId}`,accessToken);
    
    if(result.data === undefined) {
        return undefined;
    }
    
    return buildReviewCollection(result.data);
}

const buildReview = (r: any, rubricMapping?:any) : Review => {
    var authoredBy : IReviewAuthor | undefined = undefined;

    if(r.authoredBy !== undefined && r.authoredBy !== null) {
        authoredBy = {
            DisplayName: r.authoredBy.displayName,
            MembershipId: r.authoredBy.membershipId,
            StudentId: r.authoredBy.studentId
        };
    }

    var review = new Review({
        Id: r.id,
        Author: r.author,
        AuthorId: r.authorId,
        AuthoredBy: authoredBy,
        Created: new Date(r.created),
        Text: r.text,
        StudentCurriculumSubmissionId: r.studentCurriculumSubmissionId,
        Status: r.status        
    });
    
    for(let feedback of r.feedback) {
        let rId = feedback.rubricId;
        let rubricType : RubricType = RubricType.TimeScoped;
        let rubricTitle : string = '';

        if(rubricMapping?.length > 0) {
            rubricTitle = rubricMapping.find((r: any) => r.id === rId).title;
            rubricType = rubricMapping.find((r: any) => r.id === rId).type;
        }

        review.Feedback.push(new ReviewFeedback({
            Id: feedback.id,
            X: feedback.x,
            Y: feedback.y,
            Height: feedback.height,
            Width: feedback.width,
            RubricId: feedback.rubricId,
            RubricType: rubricType,
            RubricTitle: rubricTitle,
            Weight: feedback.weight,
            Timestamp: feedback.timestamp
        }));
    }

    return review;
}

const buildReviewCollection = (data: any) : ReviewCollection => {
    var reviewCollection = new ReviewCollection();
    
    reviewCollection.HasFeedback = data.hasFeedback;
    reviewCollection.ResultText = data.resultText;

    var resultRows : IResultRow[] = [];

    for(let row of data.result.rows) {
        resultRows.push({IsTotal: row.isTotal, Label: row.label, Value: row.value });
    }

    var result : IResult = {Rows: resultRows, Total: data.result.total, Type: data.result.type };

    reviewCollection.Result = result;

    for(let r of data.reviews) {        
        reviewCollection.Reviews.push(buildReview(r, data.rubricMapping));
    }    

    return reviewCollection;
}

export const getReviewHistory = async (instance: IPublicClientApplication, account: AccountInfo) : Promise<ReviewHistoryViewModel> => {
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!],instance,account);
    var result = await getData(`${process.env.REACT_APP_CURRICULUM_URI as string}review/history`,accessToken);
    
    var toReturn = new ReviewHistoryViewModel();

    for(let sub of result.data.submissions) {
        toReturn.CurriculumSubmissions.push(buildStudentCurriculumSubmission(sub));
    }

    for(let curriculum of result.data.curriculum) {
        toReturn.Curriculum.push(buildCurriculumItem(curriculum));
    }

    for(let student of result.data.students) {
        toReturn.Students.push(buildStudent(student));
    }

    for(let reviewCollection of result.data.reviews) {
        var collection = buildReviewCollection(reviewCollection);            
        toReturn.Reviews.push(collection);
    }

    return toReturn;
}

export const getActivityFeed = async (instance: IPublicClientApplication, account: AccountInfo) : Promise<ActivityFeedItem[]> => {
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!],instance,account);
    var result = await getData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}activity/feed`,accessToken);
        
    var toReturn = new Array<ActivityFeedItem>();

    for(let item of result.data) {
        var afi = new ActivityFeedItem({
                Id: item.id,
                Created: new Date(item.created),
                Category: item.category,
                Text: item.text,
                Link: item.link,
                Author: item.author,
                MembershipId: item.membershipId,
                StudentId: item.studentId,
                ImageUri: item.imageUri
            });

        toReturn.push(afi);
    }
   
    return toReturn;
}

export const getStudent = async (instance: IPublicClientApplication, account: AccountInfo, studentId: string) : Promise<Student> => {
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!],instance,account);
    var result = await getData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}student/${studentId}`,accessToken);

    return buildStudent(result.data);
}

export const queryStudents = async (instance: IPublicClientApplication, account: AccountInfo, query: string) : Promise<QueryResult[]> => {
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!],instance,account);
    if(query === undefined || query.length === 0) {
        throw Error("Query not defined");
    }

    var result = await getData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}student/find/${query}`,accessToken);

    var toReturn = new Array<QueryResult>();
    
    for(let row of result.data) {
        toReturn.push(new QueryResult({
            Id: row.id,
            MembershipId: row.membershipId,
            FirstName: row.firstName,
            LastName: row.lastName,
            ContactType: row.contactType,
            ProfileImage: row.profileImage
        }));
    }

    return toReturn;
}


export const getClassSchedule = async (instance: IPublicClientApplication, account: AccountInfo) : Promise<ScheduleViewModel | undefined> => {
    var accessToken = await getToken([process.env.REACT_APP_SCHEDULE_API_SCOPES!],instance,account);
    
    var result = await getData(`${process.env.REACT_APP_SCHEDULE_URI as string}schedule`,accessToken);

    var vm = new ScheduleViewModel();
    vm.CurrentTime = new Date(result.data.currentTime);

    for(let l of result.data.lessons) {
           vm.Lessons.push(new Lesson({
               Day: l.day as DayOfWeek,
               Delivery: l.delivery,
               Description: l.description,
               EndTime: l.endTime,
               Id: l.id,
               Instructor: new Instructor(l.instructorName, l.instructorImageUri),
               InstructorId: l.instructorId,
               IntendedAudience: getIntendedAudience(l.intendedAudience),
               JoinInstructions: l.joinInstructions,
               JoinLink: l.joinLink,
               StartTime: l.startTime,
               Title: l.title,
               EndDate: l.endDate,
               StartDate: l.startDate
           }));
    }
   
    return vm;
}

export const getCurriculumByRank = async (instance: IPublicClientApplication, account: AccountInfo, rank: number) : Promise<CurriculumItem[]> => {    
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!],instance,account);
    var result = await getData(`${process.env.REACT_APP_CURRICULUM_URI as string}curriculum/rank/${rank}`,accessToken);

    var toReturn = new Array<CurriculumItem>();

    for(let d of result.data) {
       toReturn.push(buildCurriculumItem(d));
    }
    
    return toReturn;
}

export const getCurriculumForStudent = async (instance: IPublicClientApplication, account: AccountInfo, studentId: string) : Promise<CurriculumItem[]> => {    
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!],instance,account);
    var result = await getData(`${process.env.REACT_APP_CURRICULUM_URI as string}curriculum/student/${studentId}`,accessToken);
    
    var toReturn = new Array<CurriculumItem>();

    for(let d of result.data) {
       toReturn.push(buildCurriculumItem(d));
    }
    
    return toReturn;
}

export const getPriorCurriculumForStudent = async (instance: IPublicClientApplication, account: AccountInfo, studentId: string) : Promise<CurriculumItem[]> => {    
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!],instance,account);
    var result = await getData(`${process.env.REACT_APP_CURRICULUM_URI as string}curriculum/prior/student/${studentId}`,accessToken);
    
    var toReturn = new Array<CurriculumItem>();

    for(let d of result.data) {
       toReturn.push(buildCurriculumItem(d));
    }
    
    return toReturn;
}


export enum MoveDirection {
    Up = 0,
    Down = 1,
    Top = 2,
    Bottom = 3
}

export const moveCurriculum = async (instance: IPublicClientApplication, account: AccountInfo, curriculumId: string, moveDirection: MoveDirection) : Promise<boolean> => {    
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!],instance,account);
    var statusCode = await getStatusCode(`${process.env.REACT_APP_CURRICULUM_URI as string}curriculum/move/${curriculumId}/${moveDirection}`,accessToken);

    return statusCode === 200;
}

export const deleteCurriculum = async (instance: IPublicClientApplication, account: AccountInfo, curriculumId: string) : Promise<boolean> => {    
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!],instance,account);
    var statusCode = await getStatusCode(`${process.env.REACT_APP_CURRICULUM_URI as string}curriculum/delete/${curriculumId}`,accessToken);

    return statusCode === 200;
}

export const getCurriculum = async (instance: IPublicClientApplication, account: AccountInfo, curriculumId: string) : Promise<CurriculumItem> => {    
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!],instance,account);
    var result = await getData(`${process.env.REACT_APP_CURRICULUM_URI as string}curriculum/detail/${curriculumId}`,accessToken);

    var curriculumItem = buildCurriculumItem(result.data);   

    return curriculumItem;
}

export const getCategories = async (instance: IPublicClientApplication, account: AccountInfo) : Promise<Category[]> => {    
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!],instance,account);
    var result = await getData(`${process.env.REACT_APP_CURRICULUM_URI as string}categories/all`,accessToken);

    let categories : Category[] = [];
    
    for(let category of result.data) {
        categories.push(new Category({Id:category.id, Title: category.title }));
    }
    
    return categories;
}

export const getReactionSettings = async (instance: IPublicClientApplication, account: AccountInfo) : Promise<string[]> => {    
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!],instance,account);
    var result = await getData(`${process.env.REACT_APP_ENVIRONMENT_URI as string}settings/reactions`,accessToken);

    return JSON.parse(result.data);
}

export const getReactions = async (instance: IPublicClientApplication, account: AccountInfo, studentCurriculumSubmissionId: string) : Promise<Reaction[]> => {    
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!],instance,account);
    var result = await getData(`${process.env.REACT_APP_CURRICULUM_URI as string}reaction/submission/${studentCurriculumSubmissionId}`,accessToken);

    var toReturn : Reaction[] = [];

    for(let d of result.data) {
        let values : IReactionValue[] = [];

        for(let v of d.values) {
            values.push({
                Timestamp: v.timestamp, 
                Value: v.value 
            });
        }

        toReturn.push(new Reaction({
            Author: d.author,
            AuthorId: d.authorId,
            StudentCurriculumSubmissionId: studentCurriculumSubmissionId,
            Values: values
        }));
    }

    return toReturn;
}

export const createDivisionRuleGroup = async (instance: IPublicClientApplication, account: AccountInfo, drg: IDivisionRuleGroup) : Promise<IDivisionRuleGroup | undefined> => {    
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!],instance,account);
    var response = await postData(`${process.env.REACT_APP_ENVIRONMENT_URI as string}tournament/create`,accessToken, drg);
    
    if(response?.ok) {
        var data = await response.json();

        return {
            Id: data.id,
            Name: data.name,
            Prefix: data.prefix,
            Rules: data.rules,
            Tags: data.tags
        }
    }
    
    return undefined;
}

export const createTournament = async (instance: IPublicClientApplication, account: AccountInfo,
    tournament: Tournament,
    curriculum: CurriculumItem[],
    catalog: CatalogItem[]): Promise<Response | undefined> => {    
    
    var payload = {
        Tournament: tournament,
        Curriculum: curriculum,
        Catalog: catalog
    };

    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!], instance, account);
    return await postData(`${process.env.REACT_APP_ENVIRONMENT_URI as string}tournament/create`,accessToken, payload);
}

export const updateTournament = async (instance: IPublicClientApplication, account: AccountInfo,
    tournament: Tournament,
    curriculumAdds?: CurriculumItem[],
    curriculumEdits?: CurriculumItem[],
    catalogAdds?: CatalogItem[],
    catalogEdits?: CatalogItem[]): Promise<Response | undefined> => {    
    
    var payload = {
        Tournament: tournament,
        CurriculumAdds: curriculumAdds,
        CurriculumEdits: curriculumEdits,
        CatalogAdds: catalogAdds,
        CatalogEdits: catalogEdits
    };

    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!], instance, account);
    return await postData(`${process.env.REACT_APP_ENVIRONMENT_URI as string}tournament/update`,accessToken, payload);
}

export const validateInput = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string, inputBindTo: string, value: string) : Promise<IValidationResult | undefined> => {    
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!],instance,account);
    var result = await postData(`${process.env.REACT_APP_CURRICULUM_URI as string}tournament/${tournamentId}/validate/input`,
        accessToken,
        { key: inputBindTo, value: value });
    
    if (result?.ok) {
        var data = await result.json();

        return {
            IsValid: data.isValid,
            ErrorMessage: data.errorMessage
        }
    }

    return undefined;
}

const buildUserInputWithStudents = (data: any) : IUserInputWithStudents => {
    let options: IUserInputOption[] = [];
    
    if (data.options !== null && data.options !== undefined) {
        for (let option of data.options) {
            options.push({
                Id: option.id,
                Label: option.label
            });
        }
    }
    
    return {
        TournamentId: data.tournamentId,
        StudentIds: data.studentIds,
        DataValidator: data.dataValidator,
        BindTo: data.bindTo,
        IsRequired: data.isRequired,
        Label: data.label,
        Description: data.description,
        Options: options,
        Type: data.type,
    };
}

export const readInputs = async (instance: IPublicClientApplication, account: AccountInfo): Promise<number | IUserInputWithStudents[]> => {
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!],instance,account);
    var result = await getData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}membership/read/inputs`, accessToken);
    
    if (result.statusCode !== 200) {
        return result.statusCode;    
    }
    
    var toReturn: IUserInputWithStudents[] = [];    
    
    for (let input of result.data) {
        toReturn.push(buildUserInputWithStudents(input));
    }

    return toReturn;
}

export const readInputsForStudent = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string, studentId: string): Promise<number | IUserInputWithStudents[]> => {
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!],instance,account);
    var result = await getData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}athletes/${tournamentId}/read/inputs/${studentId}`, accessToken);
    
    if (result.statusCode !== 200) {
        return result.statusCode;    
    }
    
    var toReturn: IUserInputWithStudents[] = [];    
    
    for (let input of result.data) {
        toReturn.push(buildUserInputWithStudents(input));
    }

    return toReturn;
}

export const writeInputs = async (instance: IPublicClientApplication, account: AccountInfo, inputs: IMembershipInput[]) : Promise<Response | undefined> => {    
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!],instance,account);
    return await postData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}membership/write/inputs`,accessToken, inputs);
}

export const writeReaction = async (instance: IPublicClientApplication, account: AccountInfo, reaction: Reaction) : Promise<Response | undefined> => {    
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!],instance,account);
    return await postData(`${process.env.REACT_APP_CURRICULUM_URI as string}reaction/create`,accessToken, reaction);
}

export const createCategory = async (instance: IPublicClientApplication, account: AccountInfo, newCategory: Category) : Promise<Response | undefined> => {    
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!],instance,account);
    return await postData(`${process.env.REACT_APP_CURRICULUM_URI as string}category/create/`, accessToken, newCategory);
}

export const createRubric = async (instance: IPublicClientApplication, account: AccountInfo, newRubric: Rubric) : Promise<Response | undefined> => {    
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!],instance,account);
    return await postData(`${process.env.REACT_APP_CURRICULUM_URI as string}rubric/create/`, accessToken, newRubric);   
}

export const editLevel = async (instance: IPublicClientApplication, account: AccountInfo, updateLevel: Level) : Promise<Response | undefined> => {    
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!],instance,account);
    return await postData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}levels/edit/${updateLevel.Id}`, accessToken, updateLevel);   
}

export const createLevel = async (instance: IPublicClientApplication, account: AccountInfo, newLevel: Level) : Promise<Response | undefined> => {    
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!],instance,account);
    return await postData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}levels/create/`, accessToken, newLevel);   
}

export const createProgram = async (instance: IPublicClientApplication, account: AccountInfo, newProgram: Program) : Promise<Response | undefined> => {    
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!],instance,account);
    return await postData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}programs/create/`, accessToken, newProgram);   
}

export const getRubric = async (instance: IPublicClientApplication, account: AccountInfo, category?: string) : Promise<Rubric[]> => {    
    var uri = category === undefined ?         
        `${process.env.REACT_APP_CURRICULUM_URI as string}rubric/all/` :
        `${process.env.REACT_APP_CURRICULUM_URI as string}review/rubric/${category}/`;
    
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!],instance,account);
    var result = await getData(uri, accessToken);

    var toReturn = new Array<Rubric>();

    for (const s of result.data) {            
                
        toReturn.push(
            new Rubric({
                Id: s.id, 
                Category:s.category, 
                Title: s.title,
                Type: s.type,
                Weight: s.weight                
            }));
    }

    return toReturn;
}

export const getRubricPointsById = async (instance: IPublicClientApplication, account: AccountInfo, rubricIds: string[]) : Promise<RubricPoints[]> => {    
    var uri = `${process.env.REACT_APP_CURRICULUM_URI as string}review/rubric/points/${rubricIds.join('|')}`;
    
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!],instance,account);
    var result = await getData(uri, accessToken);

    var toReturn = new Array<RubricPoints>();

    for (const s of result.data) {            
                
        toReturn.push(
            new RubricPoints({
                Id: s.id,
                RubricIds: s.rubricIds,
                Max: s.max,
                Min: s.min,
                Value: s.value,
                ScoreImpact: s.scoreImpact                
            }));
    }

    return toReturn;
}


export const getRubricPoints = async (instance: IPublicClientApplication, account: AccountInfo, rubric: Rubric[]) : Promise<RubricPoints[]> => {    
    var ids : string[] = [];

    for(let r of rubric) {
        ids.push(r.Id);
    }
    
    return getRubricPointsById(instance, account, ids);
}

export const getNextPromotionTest = async (instance: IPublicClientApplication, account: AccountInfo) : Promise<EventModel> => {    
    var accessToken = await getToken([process.env.REACT_APP_SCHEDULE_API_SCOPES!],instance,account);
    var result = await getData(`${process.env.REACT_APP_SCHEDULE_URI as string}event/nextpromotiontest`,accessToken);

    return new EventModel({
        // ContentDelivery: data.contentDelivery,
        Description: result.data.description,
        End: result.data.end,
        Id: result.data.id,
        ImageUri: result.data.imageUri,
        Start: result.data.start,
        Title: result.data.title,
        Type: result.data.type,
        Uri: result.data.uri
    });
}

export const postFeedback = async (instance: IPublicClientApplication, account: AccountInfo, feedback: Feedback) : Promise<Response | undefined> => {    
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!],instance,account);
    var result = await postData(`${process.env.REACT_APP_ENVIRONMENT_URI as string}feedback/create`, accessToken, feedback);
    
    return result;
}

export const postAccountRecovery = async (email: string) : Promise<Response | undefined> => {        
    var result = await postDataNoAuth(`${process.env.REACT_APP_PROVISIONING_URI as string}account/recovery`, { value: email });
    return result;
}

export const postBeginLogin = async (email: string) : Promise<number | { issuer: string, user: string, email: string}> => {        
    var result = await postDataNoAuth(`${process.env.REACT_APP_PROVISIONING_URI as string}account/login`, { value: email });

    if(result?.ok) {
        var data = await result.json();        
        return { issuer: data.issuer, user: data.objectId, email: data.email };
    }
    
    return result === undefined ? 500 : result.status;
}

export const postUpdateUser = async (instance: IPublicClientApplication, account: AccountInfo, id: string, vm: UserInvitation) : Promise<Response | undefined> => {    
    var accessToken = await getToken([process.env.REACT_APP_PROVISIONING_API_SCOPES!],instance,account);
    var result = await postData(`${process.env.REACT_APP_PROVISIONING_URI as string}user/update/${id}`, accessToken, vm);
    
    return result;
}

export const postDeleteUser = async (instance: IPublicClientApplication, account: AccountInfo, email: string) : Promise<boolean> => {    
    var accessToken = await getToken([process.env.REACT_APP_PROVISIONING_API_SCOPES!],instance,account);
    var result = await getStatusCode(`${process.env.REACT_APP_PROVISIONING_URI as string}user/delete/${email}`, accessToken);

    return result === 200;
}

export const postUpdatedInvitation = async (instance: IPublicClientApplication, account: AccountInfo, email: string, userInvitation: UserInvitation) : Promise<Response|undefined> => {    
    var accessToken = await getToken([process.env.REACT_APP_PROVISIONING_API_SCOPES!],instance,account);
    return await postData(`${process.env.REACT_APP_PROVISIONING_URI as string}user/invite/update/${email}`, accessToken, userInvitation);    
}

export const resendUserInvitation = async (instance: IPublicClientApplication, account: AccountInfo, membershipId: string) : Promise<boolean> => {    
    var accessToken = await getToken([process.env.REACT_APP_PROVISIONING_API_SCOPES!],instance,account);
    var result = await getStatusCode(`${process.env.REACT_APP_PROVISIONING_URI as string}user/invite/resend/${membershipId}`, accessToken);

    return result === 200;
}

export const getUserInvitationLink = async (instance: IPublicClientApplication, account: AccountInfo, email: string) : Promise<UserInvitationData> => {    
    var accessToken = await getToken([process.env.REACT_APP_PROVISIONING_API_SCOPES!],instance,account);
    var result = await getData(`${process.env.REACT_APP_PROVISIONING_URI as string}user/invite/getlink/${email}`, accessToken);

    return new UserInvitationData(result.data.link, new Date(result.data.expiry));    
}

export const postUserInvitation = async (instance: IPublicClientApplication, account: AccountInfo, object: UserInvitation) : Promise<Response|undefined> => {    
    var accessToken = await getToken([process.env.REACT_APP_PROVISIONING_API_SCOPES!],instance,account);
    return await postData(`${process.env.REACT_APP_PROVISIONING_URI as string}user/invite/`, accessToken, object);    
}

export const postInvitations = async (instance: IPublicClientApplication, account: AccountInfo, object: any, promotionTestId: string) : Promise<Response|undefined> => {    
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!],instance,account);
    return await postData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}student/invite/promotion/${promotionTestId}`, accessToken, object);    
}

export const postCurriculum = async (instance: IPublicClientApplication, account: AccountInfo, object: CurriculumItem) : Promise<Response|undefined> => {    
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!],instance,account);
    return await postData(`${process.env.REACT_APP_CURRICULUM_URI as string}curriculum/create`, accessToken, object);    
}

export const updateCurriculum = async (instance: IPublicClientApplication, account: AccountInfo, object: CurriculumItem) : Promise<Response|undefined> => {    
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!],instance,account);
    return await postData(`${process.env.REACT_APP_CURRICULUM_URI as string}curriculum/update`, accessToken, object);    
}


export const postReview = async (instance: IPublicClientApplication, account: AccountInfo, object: any, tournamentId?:string) : Promise<Response|undefined> => {    
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!],instance,account);

    if(tournamentId !== undefined) {
        return await postData(`${process.env.REACT_APP_CURRICULUM_URI as string}review/create/tournament/${tournamentId}`, accessToken, object);
    }
    else{
        return await postData(`${process.env.REACT_APP_CURRICULUM_URI as string}review/create`, accessToken, object);
    }
}

export const getInvitationStatus = async (invitationId: string) : Promise<InvitationTokenState> => {        
    var statusCode = await getStatusCode(`${process.env.REACT_APP_PROVISIONING_URI as string}invitation/status/${invitationId}`);
    
    if (statusCode === 200) {
        return InvitationTokenState.Valid;
    }

    if(statusCode === 201) {
        return InvitationTokenState.Resent;
    }

    return statusCode === 404 ? InvitationTokenState.NotFound : InvitationTokenState.Invalid;
}

export const getTournamentStatus = async (token: string) : Promise<TournamentStatus | number> => {
    var result = await getData(`${process.env.REACT_APP_SCHEDULE_URI as string}status/${token}`);
    
    if(result.data === undefined) {
        return result.statusCode;
    }

    var ts : ITournamentStatus = {} as ITournamentStatus;
    ts.Title = result.data.title;
    ts.Link = result.data.link;
    ts.Rows = [];

    for(let row of result.data.rows) {
        var rowValues: IRowValue[] = [];
        
        for(let rv of row.values) {
            rowValues.push({
                Value: rv.value
            })
        }

        ts.Rows.push({
            Values:rowValues
        })
    }
    
    ts.ColumnHeaders = [];

    for(let ch of result.data.columnHeaders) {
        ts.ColumnHeaders.push({            
            PreferredWidth: ch.preferredWidth,
            Value: ch.value,
            ValueKind: ch.valueKind
        });
    }

    return ts;
}

export const getTournamentTerms = async (tournamentId: string) : Promise<{title:'',terms:''} | number> => {
    var result = await getData(`${process.env.REACT_APP_PROVISIONING_URI as string}tournament/${tournamentId}/terms`);
        
    if(result.data === undefined) {
        return result.statusCode;
    }
    
    return {
        terms: result.data.terms,
        title: result.data.title
    };
}

export const getTournamentLandingPageContent = async (tournamentId: string) : Promise<TournamentLandingPageViewModel | number> => {
    var result = await getData(`${process.env.REACT_APP_PROVISIONING_URI as string}content/${tournamentId}`);
        
    if(result.data === undefined) {
        return result.statusCode;
    }
    
    var events : ILandingPageEvent[] = [];

    if(result.data.events !== null) {
        for(let d of result.data.events) {
            events.push({
                ImageUri: d.imageUri, 
                Title: d.title                
            });
        }
    }
    
    var tournament = {
        Id: result.data.tournament.id,
        Type: result.data.tournament.type,
        Description: result.data.tournament.description,
        EventLandingPage: result.data.tournament.eventLandingPage,
        ImageUri: result.data.tournament.imageUri,
        Layout: result.data.tournament.layout,
        Timezone: result.data.tournament.timezone,
        Title: result.data.tournament.title,
        Location: result.data.tournament.location,
        RegistrationEndDate: new Date(result.data.tournament.registrationEndDate),
        StartDate: result.data.tournament.startDate !== undefined ? new Date(result.data.tournament.startDate) : undefined,
        EndDate: result.data.tournament.endDate !== undefined ? new Date(result.data.tournament.endDate) : undefined
    }

    return new TournamentLandingPageViewModel({
        CanJoin: result.data.canJoin,
        Events: events,
        Tournament: tournament        
    });
}


export const getTenantLandingPageContent = async () : Promise<TenantLandingPageViewModel | number> => {
    var result = await getData(`${process.env.REACT_APP_PROVISIONING_URI as string}content`);
        
    if(result.data === undefined) {
        return result.statusCode;
    }
    
    var tournaments : ILandingPageTournament[] = [];

    if(result.data.tournaments !== null) {
        for(let p of result.data.tournaments) {        

            tournaments.push({
                Id: p.id,
                Type: p.type,
                Layout: p.layout,
                Description: p.description,
                EventLandingPage: p.eventLandingPage,
                Title: p.title,
                Location: p.location,
                Timezone: p.timezone,
                ImageUri: p.imageUri,                
                RegistrationEndDate: new Date(p.registrationEndDate),
                StartDate : p.startDate !== undefined ? new Date(p.startDate) : undefined,
                EndDate : p.endDate !== undefined ? new Date(p.endDate) : undefined
            })
        }
    }

    return new TenantLandingPageViewModel({
        EnvDescription: result.data.envDescription,
        EnvTitle: result.data.envTitle,
        CanJoin: result.data.canJoin,
        Tournaments: tournaments        
    });
}

export const getViralJoinEnv = async () : Promise<ViralJoinEnv | undefined> => {
    var result = await getData(`${process.env.REACT_APP_PROVISIONING_URI as string}community/env`);
    
    if(result.data === undefined) {
        return undefined;
    }

    var programs : Program[] = [];
    
    for(let p of result.data.programs) {
        programs.push(new Program({
            Id: p.id,
            Name: p.name
        }));
    }

    var levels : Level[] = [];

    for(let l of result.data.levels) {
        levels.push(new Level({
            Description: l.description,
            Grade: l.grade,
            Label: l.label,
            Id: l.id,
            ProgramIds: l.programIds,
            Order: l.order
        }));
    }

    var trainingFacilities : TrainingFacility[] = [];

    for(let f of result.data.trainingFacilities) {
        trainingFacilities.push(new TrainingFacility({
            Id: f.id,
            Location: f.location,
            Title: f.title
        }));
    }

    return new ViralJoinEnv(
        result.data.title, 
        levels, 
        programs, 
        result.data.requestStudentDob, 
        result.data.requestStudentGender, 
        trainingFacilities,
        result.data.canAddTrainingFacility);
}

export const exportDivisionRules = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string): Promise<number | IFileDownload> => {
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!], instance, account);
    var result = await getData(`${process.env.REACT_APP_ENVIRONMENT_URI as string}tournament/${tournamentId}/divisionrules/export`, accessToken);

    if(result.data === undefined) {
        return result.statusCode;
    }

    return { Filename: result.data.filename, Uri: result.data.value };
}

export const importDivisionRules = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string, fileData: File): Promise<boolean> => {
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!], instance, account);
    var result = await postFile(`${process.env.REACT_APP_ENVIRONMENT_URI as string}tournament/${tournamentId}/divisionrules/import`, accessToken, fileData);

    return result !== undefined;
}

export interface IEventPreview {
    Title: string;
    RulesAdded: number;
    RulesRemoved: number;
    Gaps: string[];
}
export interface IImportRulesPreview {
    Id: string;
    Modifications: IEventPreview[];
}

export const importDivisionRulesWithPreviewId = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string, previewId: string): Promise<boolean> => {
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!], instance, account);
    var result = await getData(`${process.env.REACT_APP_ENVIRONMENT_URI as string}tournament/${tournamentId}/divisionrules/import/${previewId}`, accessToken);

    return result !== undefined;
}

export const previewImportDivisionRules = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string, fileData: File): Promise<number | IImportRulesPreview> => {
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!], instance, account);
    var result = await postFile(`${process.env.REACT_APP_ENVIRONMENT_URI as string}tournament/${tournamentId}/divisionrules/import/preview`, accessToken, fileData);

    if (result === undefined) {
        return 500;
    }

    if (!result.ok) {
        return result.status;
    }
    
    let data = await result.json();
    
    let modifications: IEventPreview[] = [];

    for (let m of data.modifications) {
        modifications.push({
            Title: m.title,
            RulesAdded: m.rulesAdded,
            RulesRemoved: m.rulesRemoved,
            Gaps: m.gaps
        });        
    }
    
    
    return {
        Modifications: modifications
    } as IImportRulesPreview;
}

export const downloadBulkRegistrationImportTemplate = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string) : Promise<number | IFileDownload> => {    
    var accessToken = await getToken([process.env.REACT_APP_PROVISIONING_API_SCOPES!],instance,account);    
    var result = await getData(`${process.env.REACT_APP_PROVISIONING_URI as string}registrations/import/${tournamentId}/bulk/template`, accessToken);
    
    return { Filename: result.data.filename, Uri: result.data.value };
}

export const getBulkRegistrationImportPreview = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string, uri:string) : Promise<[UserInvitation[],ImportError[]] | undefined> => {    
    var accessToken = await getToken([process.env.REACT_APP_PROVISIONING_API_SCOPES!],instance,account);    
    var response = await postData(`${process.env.REACT_APP_PROVISIONING_URI as string}registrations/import/${tournamentId}/bulk/preview/`, accessToken, { uri: uri });
    
    if (response?.ok) {
        var data = await response.json();
        
        var validEntries = new Array<UserInvitation>();
        var errors = new Array<ImportError>();

        //data.result comes with errors 
        for(let i of data.rows) {            
            var students = new Array<Student>();

            for(let s of i.students) {
                students.push(buildStudent(s));                
            }

            validEntries.push(new UserInvitation({
                Emails: i.emails,
                CustomMessage: i.customMessage,
                Roles: i.roles,
                Students: students,
                MarketingConsent: i.marketingConsent
            }));
        }

        if (data.result.errors !== null) {
            for(let e of data.result.errors) {
                errors.push(new ImportError(e.row, e.error));
            }    
        }
        
        return [validEntries, errors];
    }
    
    return undefined;
}

export const postBulkRegistrationImport = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string, uri:string) : Promise<boolean> => {    
    var accessToken = await getToken([process.env.REACT_APP_PROVISIONING_API_SCOPES!],instance,account);    
    var result = await postData(`${process.env.REACT_APP_PROVISIONING_URI as string}registration/import/${tournamentId}/bulk/`, accessToken, { uri: uri });
    
    return result?.ok ? true : false;
}

export const getTournamentEnv = async (instance: IPublicClientApplication, account: AccountInfo) : Promise<SubmissionEnv|undefined> => {
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!], instance, account);
    var result = await getData(`${process.env.REACT_APP_ENVIRONMENT_URI as string}tournament/setup/`, accessToken);
    return new SubmissionEnv(result.data.filename, result.data.sasToken, result.data.container, result.data.endpoint);
}

export const getCurriculumEnv = async (instance: IPublicClientApplication, account: AccountInfo) : Promise<SubmissionEnv|undefined> => {    
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!],instance,account);
    var result = await getData(`${process.env.REACT_APP_CURRICULUM_URI as string}curriculum/setup/`, accessToken);
    return new SubmissionEnv(result.data.filename, result.data.sasToken, result.data.container, result.data.endpoint);
}

export const getBulkRegistrationImportEnv = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string) : Promise<SubmissionEnv|undefined> => {    
    var accessToken = await getToken([process.env.REACT_APP_PROVISIONING_API_SCOPES!],instance,account);
    var result = await getData(`${process.env.REACT_APP_PROVISIONING_URI as string}registrations/import/${tournamentId}/bulk/setup/`, accessToken);
    return new SubmissionEnv(result.data.filename, result.data.sasToken, result.data.container, result.data.endpoint);
}

export const getCurriculumSubmissionEnv = async (instance: IPublicClientApplication, account: AccountInfo, studentId: string, curriculumId: string) : Promise<SubmissionEnv|undefined> => {    
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!],instance,account);
    var result = await getData(`${process.env.REACT_APP_CURRICULUM_URI as string}curriculum/submissions/setup/${curriculumId}/student/${studentId}`, accessToken);
    return new SubmissionEnv(result.data.filename, result.data.sasToken, result.data.container, result.data.endpoint);
}

export const postStudentCurriculumSubmission = async (instance: IPublicClientApplication, account: AccountInfo, curriculumId: string, studentId: string, filename: string, contentType: string) : Promise<Response|undefined> => {    
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!],instance,account);
    return await postData(`${process.env.REACT_APP_CURRICULUM_URI as string}curriculum/submissions/create/${curriculumId}/student/${studentId}`, accessToken, {filename: filename, contentType: contentType});    
}

export const postStudentToMembership = async (instance: IPublicClientApplication, account: AccountInfo, student: Student) : Promise<Student|undefined> => {    
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!],instance,account);
    var result = await postData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}student/create`, accessToken, student);

    if(!result?.ok) {
        return undefined;
    }

    return buildStudent(await result.json());
}

export const postStudentUpdate = async (instance: IPublicClientApplication, account: AccountInfo, student: Student) : Promise<Response|undefined> => {    
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!],instance,account);
    return await postData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}student/update/${student.Id}`, accessToken, student);
}

export const postCommunityJoinRequest = async (userInvitation: UserInvitation) : Promise<Response|undefined> => {        
    return await postDataNoAuth(`${process.env.REACT_APP_PROVISIONING_URI as string}community/join`, userInvitation);
}

export const putStudentCurriculumSubmission = async (instance: IPublicClientApplication, account: AccountInfo, curriculumId: string, studentId: string, blob: Blob) : Promise<Response|undefined> => {    
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!],instance,account);
    return await putData(`${process.env.REACT_APP_CURRICULUM_URI as string}curriculum/submissions/create/${curriculumId}/student/${studentId}`, accessToken, blob);    
}


export const getStudentDashboard = async (instance: IPublicClientApplication, account: AccountInfo, studentId: string) : Promise<StudentDashboardModel> => {
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!],instance,account);
    var result = await getData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}student/dashboard/${studentId}`, accessToken);

    var toReturn = new StudentDashboardModel();

    toReturn.Student = buildStudent(result.data.student);

    for(let ph of result.data.promotionHistory) {
        toReturn.Student.PromotionHistory.push(new StudentPromotion({
            Date: new Date(ph.date),
            EventId: ph.eventId,
            Id: ph.id,
            RankIn: ph.rankIn,
            RankOut: ph.rankOut,
            StudentId: ph.studentId
        }));
    }

    for(let sub of result.data.curriculumSubmissions) {
        toReturn.CurriculumSubmissions.push(buildStudentCurriculumSubmission(sub));
    }
    for(let ci of result.data.curriculumItems) {
        toReturn.CurriculumItems.push(buildCurriculumItem(ci));
    }

    //note this is a shallow review, does not include review feedback
    for(let r of result.data.reviews) {
        toReturn.Reviews.push(new Review({
            Id: r.id,
            Author: r.author,
            AuthorId: r.authorId,
            Created: new Date(r.created),
            Text: r.text,
            StudentCurriculumSubmissionId: r.studentCurriculumSubmissionId,
            Status: r.status        
        }));
    }

    toReturn.HasInvitationToTest = result.data.hasInvitationToTest;
    toReturn.HasRegisteredForTest = result.data.hasRegisteredForTest;

    return toReturn;
}

export const getStudentCurriculum = async (instance: IPublicClientApplication, account: AccountInfo, studentId: string) : Promise<Array<CurriculumItem>> => {    
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!],instance,account);
    var result = await getData(`${process.env.REACT_APP_CURRICULUM_URI as string}curriculum/student/${studentId}`,accessToken);

    var curriculumItems = Array<CurriculumItem>();

    for (const s of result.data) {  
        var curriculumItem = new CurriculumItem(buildCurriculumItem(s));

        if(result.data.resources !== undefined && result.data.resources !== null) {
            for (const r of result.data.resources) {
                curriculumItem.Resources?.push(new Resource(
                    {
                        Description: r.description,
                        Name:r.name,
                        MediaType: r.mediaType,
                        Uri: r.uri
                    }));
            }
        }

        curriculumItems.push(curriculumItem);
    }

    return curriculumItems;
}

const buildStudentCurriculumSubmission = (data: any) : StudentCurriculumSubmission => {
    var reviews : Review[] = [];
    
    if(data.reviews !== undefined && data.reviews !== null) {
        for(let r of data.reviews) {
            reviews.push(buildReview(r));
        }
    }

    return new StudentCurriculumSubmission({
        Id: data.id,
        Reviews: reviews,
        StudentId: data.studentId,
        StudentLevelIdWhenSubmitted: data.studentLevelIdWhenSubmitted,
        StudentLevelWhenSubmitted: new Level({
            Id: data.studentLevelWhenSubmitted.id,
            Description: data.studentLevelWhenSubmitted.description,
            Grade: data.studentLevelWhenSubmitted.grade,
            Label: data.studentLevelWhenSubmitted.label,
            Order: data.studentLevelWhenSubmitted.order,
            ProgramIds: data.studentLevelWhenSubmitted.programIds,
            }),
        MembershipId: data.membershipId,
        PosterImageUri: data.posterImageUri,
        ContentType: data.contentType,
        ContentUri: data.contentUri,
        CurriculumId: data.curriculumId,
        Created: new Date(data.created),
        Status: data.status            
    });    
}

export const getStudentCurriculumSubmission = async (instance: IPublicClientApplication, account: AccountInfo, submissionId: string) : Promise<StudentCurriculumSubmission> => {    
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!], instance, account);
    var result = await getData(`${process.env.REACT_APP_CURRICULUM_URI as string}curriculum/submissions/id/${submissionId}`, accessToken);

    return buildStudentCurriculumSubmission(result.data);
}

export const getSubmissionsForBracket = async(instance: IPublicClientApplication, account: AccountInfo, tournamentId: string, divisionId: string, bracketId: string) : Promise<BracketReviewViewModel> => {    
    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!], instance, account);
    var result = await getData(`${process.env.REACT_APP_ENVIRONMENT_URI as string}tournament/${tournamentId}/division/${divisionId}/bracket/${bracketId}`, accessToken);

    var submissions : StudentCurriculumSubmission[] = [];
    var students : Student[] = [];

    for(let sub of result.data.submissions) {
        submissions.push(buildStudentCurriculumSubmission(sub));
    }

    for(let student of result.data.students) {
        students.push(buildStudent(student));
    }

    return new BracketReviewViewModel({
        Students:students, 
        Submissions: submissions,
        Curriculum: buildCurriculumItem(result.data.curriculum),
        DivisionName: result.data.divisionName,
        ReviewPolicy: buildReviewPolicy(result.data.reviewPolicy)
    });
}

const buildReviewPolicy = (data: any) : ReviewPolicy | undefined => {
    if(data === null || data === undefined) {
        return undefined;
    }

    return new ReviewPolicy({
        RequiredReviewers: data.requiredReviewers,
        RequiredReviews: data.requiredReviews,
        ScoreType: data.scoreCalculation
    })
}

export const cancelAssignedBrackets = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string, req: IAthleteCallRequest) : Promise<boolean> => {    
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!], instance, account);
    var result = await postData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}athletecall/${tournamentId}/undostaged`,accessToken, req);
    
    return result!.ok;
}

export const cancelRequestToStageAthletes = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string, req: IAthleteCallRequest) : Promise<boolean> => {    
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!], instance, account);
    var result = await postData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}athletecall/${tournamentId}/undostaging`,accessToken, req);
    
    return result!.ok;
}

export const postRequestToStageAthletes = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string, req: IAthleteCallRequest) : Promise<boolean> => {    
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!], instance, account);
    var result = await postData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}athletecall/${tournamentId}/staging`,accessToken, req);
    
    if(result === undefined) {
        return false;
    }
    
    return result?.ok;
}

export const postStagedBracketsToLocation = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string, req: IAthleteCallRequest) : Promise<boolean> => {    
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!], instance, account);
    var result = await postData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}athletecall/${tournamentId}/assignlocation`,accessToken, req);
    
    if(result === undefined) {
        return false;
    }
    
    return result?.ok;
}

export const sendInvoiceReminder = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string, invoiceId: string): Promise<boolean> => {
    var accessToken = await getToken([process.env.REACT_APP_PAYMENT_API_SCOPES!], instance, account);
    var result = await getStatusCode(`${process.env.REACT_APP_PAYMENT_URI as string}invoices/sendreminder/${tournamentId}/id/${invoiceId}`, accessToken);

    return result === 200;
}

export const resendReceipt = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string, transactionId: string): Promise<boolean> => {
    var accessToken = await getToken([process.env.REACT_APP_PAYMENT_API_SCOPES!], instance, account);
    var result = await getStatusCode(`${process.env.REACT_APP_PAYMENT_URI as string}transactions/${tournamentId}/receipt/${transactionId}/resend`, accessToken);

    return result === 200;

}

export const postNewInvoice = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string, invoice: ICreateInvoiceRequest): Promise<IInvoice | undefined> => {
    var accessToken = await getToken([process.env.REACT_APP_PAYMENT_API_SCOPES!], instance, account);
    var result = await postData(`${process.env.REACT_APP_PAYMENT_URI as string}invoices/new/${tournamentId}`, accessToken, invoice);

    if (!result?.ok) {
        return undefined;
    }

    var data = await result.json();
    return buildInvoice(data);
}

export const postRequestToAddEventRegistration = async (instance: IPublicClientApplication, account: AccountInfo, req: IChangeRegistrationRequest) : Promise<boolean> => {    
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!], instance, account);
    var result = await postData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}registrations/add`,accessToken, req);
    
    if(result === undefined) {
        return false;
    }
    
    return result?.ok;
}

export const postRequestToChangeEventRegistration = async (instance: IPublicClientApplication, account: AccountInfo, req: IChangeRegistrationRequest) : Promise<boolean> => {    
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!], instance, account);
    var result = await postData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}registrations/change`,accessToken, req);
    
    if(result === undefined) {
        return false;
    }
    
    return result?.ok;
}

export const getDivisionStatus = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string, eventId: string) : Promise<IDivisionStatus | number> => {    
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!], instance, account);
    var result = await getData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}athletecall/${tournamentId}/event/${eventId}`,accessToken);
    
    if(result.data === undefined) {
        return result.statusCode;
    }
    
    var toReturn : IDivisionStatus = {} as IDivisionStatus;
    var brackets: ITournamentBracket[] = [];
    var divisions : ITournamentDivision[] = [];

    for(let r of result.data.divisions) {
        divisions.push({
            Id: r.id,
            StatusModified: new Date(r.statusModified),
            LastStatus: r.lastStatus,
            Status: r.status,
            EventId: r.eventId,
            Description: r.description,
            Name: r.name,
            TournamentId: r.tournamentId            
        });  
    }

    for(let b of result.data.brackets) {
        var rounds : ITournamentRound[] = [];

        for(let round of b.rounds) {
            var matches : ITournamentMatch[] = [];
            
            for(let m of round.matches) {                
                matches.push({
                    CompetitorIds: m.competitorIds,
                    Scores: []
                });
            }

            rounds.push({
                Matches: matches,
                LastStatus: round.lastStatus,
                Sequence: round.sequence,
                Status: round.status
            });
        }

        brackets.push({
            Id: b.id,
            DivisionId: b.divisionId,
            ScheduledStart: new Date(b.scheduledStart),
            Status: b.status,
            LastStatus: b.lastStatus,
            Label: b.label,
            Location: b.location, 
            Rounds: rounds,
            TournamentId: b.tournamentId
        });  
    }

    toReturn.AthleteCallMinutes = result.data.athleteCallMinutes;
    toReturn.StagingLocations = result.data.stagingLocations;
    toReturn.BracketLocations = result.data.bracketLocations;
    toReturn.Brackets = brackets;
    toReturn.Divisions = divisions;

    return toReturn;
}

export const postAthleteUpdate  = async (instance: IPublicClientApplication, account: AccountInfo, athlete: Student, tournamentId: string) : Promise<Response|undefined> => {    
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!],instance,account);
    return await postData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}athletes/${tournamentId}/update/${athlete.Id}`, accessToken, athlete);
}

export const getAthletes = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string) : Promise<Student[] | number> => {    
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!], instance, account);
    var result = await getData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}athletes/${tournamentId}/list`,accessToken);
    
    if(result.data === undefined) {
        return result.statusCode;
    }
    
    var toReturn : Student[] = [];

    for(let r of result.data) {
        toReturn.push(buildStudent(r));
    }

    return toReturn;
}

export const getAthleteCallStatus = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string) : Promise<IAthleteCallState | number> => {    
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!], instance, account);
    var result = await getData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}athletecall/${tournamentId}/status`,accessToken);
    
    if(result.data === undefined) {
        return result.statusCode;
    }
    
    var toReturn: IAthleteCallState = {
        BracketGenerationDate: new Date(result.data.bracketGenerationDate),
        Events: []
    };

    for(let r of result.data.events) {
        toReturn.Events.push({
            EventId: r.eventId,
            EventTitle: r.eventTitle,
            ImageUri: r.imageUri,
            PendingDivisions: r.pendingDivisions,
            StagingDivisions: r.stagingDivisions,
            CompetingDivisions: r.competingDivisions,
            NumberOfStudents: r.numberOfStudents
        });  
    }

    return toReturn;
}

const buildInvoice = (data: any): IInvoice => {
    let eventChanges: IEventChange[] = [];
    
    for (var ec of data.eventsChanged) {
        eventChanges.push({
            EventId: ec.eventId,
            EventName: ec.eventName,
            StudentId: ec.studentId,
            Type: ec.type
        });
    }
    
    return {
        ClientSecret: data.clientSecret,
        Created: new Date(data.created),
        Modified: new Date(data.modified),
        Paid: data.paid === null ? undefined : new Date(data.paid),
        Description: data.description,
        EventChanges: eventChanges,
        Number: data.number,
        TournamentImageUri: data.tournamentImageUri,
        TournamentName: data.tournamentName,
        TransactionId: data.transactionId,        
        Status: data.status,
        Total: data.total,
        TournamentId: data.tournamentId,
        Id: data.id,
        UserId: data.userId
    }

}

export const getInvoice = async (instance: IPublicClientApplication, account: AccountInfo, invoiceId: string): Promise<IInvoice | number> => {
    var accessToken = await getToken([process.env.REACT_APP_PAYMENT_API_SCOPES!], instance, account);
    var result = await getData(`${process.env.REACT_APP_PAYMENT_URI as string}invoice/read/${invoiceId}`, accessToken);

    if (result.data === undefined) {
        return result.statusCode;
    }

    return buildInvoice(result.data);
}

export const getTournamentSummary = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string): Promise<TournamentSummary | number> => {
    var accessToken = await getToken([process.env.REACT_APP_PAYMENT_API_SCOPES!], instance, account);
    var result = await getData(`${process.env.REACT_APP_PAYMENT_URI as string}registrations/summary/${tournamentId}`, accessToken);

    if (result.data === undefined) {
        return result.statusCode;    
    }

    var tournamentSummary = new TournamentSummary();
    tournamentSummary.Events = [];
    tournamentSummary.Catalog = [];
    tournamentSummary.Roles = [];
    tournamentSummary.Disbursements = [];
    
    tournamentSummary.NpnlFees = result.data.npnlFees;
    tournamentSummary.CardProcessorFees = result.data.cardProcessorFees;
    tournamentSummary.TotalCollected = result.data.totalCollected;

    for (var d of result.data.disbursements) {
        tournamentSummary.Disbursements.push({
            Amount: d.amount,
            AmountWitheld: d.amountWitheld,
            Created: new Date(d.created),
            CardProcessingFees: d.cardProcessingFees,
            PerAthleteFee: d.perAthleteFee,
            Method: d.method,
            PeriodEnd: new Date(d.periodEnd),
            PeriodStart: new Date(d.periodStart)
        });
    }

    for (var es of result.data.events) {
        tournamentSummary.Events.push({
            DivisionsWithoutMatch: es.divisionsWithoutMatch,
            EventName: es.eventName,
            NumAthletes: es.numAthletes
        });
    }

    for (var r of result.data.roles) {
        tournamentSummary.Roles.push({
            Role: r.role,
            NumMembers: r.numMembers
        });
    }

    for (var c of result.data.catalog) {
        tournamentSummary.Catalog.push({
            ItemName: c.itemName,
            Configuration: c.configuration,
            Name: c.name,
            NumPurchases: c.numPurchases
        });
    }

    return tournamentSummary;
}

export const getTournamentReviewDashboard = async (instance: IPublicClientApplication, account: AccountInfo, tournamentId: string) : Promise<TournamentReviewDashboardViewModel> => {    

    var accessToken = await getToken([process.env.REACT_APP_ENVIRONMENT_API_SCOPES!], instance, account);
    var result = await getData(`${process.env.REACT_APP_ENVIRONMENT_URI as string}tournament/${tournamentId}/reviewdashboard`, accessToken);

    var vm = new TournamentReviewDashboardViewModel();

    var events : ITournamentEvent[] = [];    

    for(let d of result.data.events) {
        var divisions : ITournamentDivisionViewModel[] = [];
        var students : Student[] = [];        
        var submissions : StudentCurriculumSubmission[] = [];        

        for(let div of d.divisions) {
            var registrations : ITournamentEventRegistration[] = [];

            for(let r of div.eventRegistrations) {            
                registrations.push({
                    Created: new Date(r.created),
                    CurriculumId: r.curriculumId,
                    Id: r.Id,
                    StudentId: r.studentId,
                    SubmissionId: r.submissionId,
                    Role: TournamentRole.Participant,
                    Status: r.status
                });
            }

            var brackets : TournamentBracket[] = [];

            for(let b of div.brackets) {
                var athletes: IBracketedAthlete[] = [];

                for(let a of b.athletes) {
                    athletes.push({Sequence: a.sequence, Status: a.status, StudentId: a.studentId});
                }

                var rounds: ITournamentRound[] = [];
                
                for(let rnd of b.rounds) {
                    var matches: TournamentMatch[] = [];
                    
                    for(let m of rnd.matches) {
                        var scores : Score[] = [];
                        matches.push(new TournamentMatch([...m.competitorIds], scores));
                    }
                    
                    rounds.push({ Sequence: rnd.sequence, LastStatus: rnd.lastStatus, Status: rnd.status, Matches: matches});
                }

                brackets.push(new TournamentBracket(b.id, b.label, athletes, b.judgeIds === null ? [] : [...b.judgeIds], rounds));
            }

            divisions.push({
                EventId: div.eventId, 
                Brackets: brackets,               
                Id: div.id,
                Name: div.name,
                EventRegistrations: registrations
            })
        }
        
        for(let s of d.submissions) {
            submissions.push(buildStudentCurriculumSubmission(s));
        }

        for(let t of d.students) {
            students.push(buildStudent(t));
        }

        events.push({
            ImageUri: d.imageUri,
            EventName: d.eventName,
            Divisions: divisions,
            Students: students,
            Submissions: submissions
        })
    }

    var roles : ITournamentStakeholderRole[] = [];

    for(let role of result.data.roles) {
        roles.push({
            Value: role.value,
            StudentIds: [...role.studentIds]
        });
    }

    vm.Events = events;
    vm.Roles = roles;
    return vm;
}

export const getStudentCurriculumSubmissions = async (instance: IPublicClientApplication, account: AccountInfo, curriculumId: string, studentId: string) : Promise<Array<StudentCurriculumSubmission>> => {    
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!], instance, account);
    var result = await getData(`${process.env.REACT_APP_CURRICULUM_URI as string}curriculum/submissions/curriculumid/${curriculumId}/student/${studentId}`, accessToken);

    var curriculumItems = Array<StudentCurriculumSubmission>();

    for (const s of result.data) {  
        curriculumItems.push(buildStudentCurriculumSubmission(s));
    }

    return curriculumItems;
}

export const getAllStudentCurriculumSubmissions = async (instance: IPublicClientApplication, account: AccountInfo, status: SubmissionStatus) : Promise<ReviewDashboardViewModel> => {    
    var accessToken = await getToken([process.env.REACT_APP_CURRICULUM_API_SCOPES!],instance,account);
    var result = await getData(`${process.env.REACT_APP_CURRICULUM_URI as string}curriculum/submissions/status/${status}`,accessToken);

    var toReturn = new ReviewDashboardViewModel();
    
    var submissions = new Array<StudentCurriculumSubmission>();
    var students = new Array<Student>();
    // var invitations = new Array<Invitation>();
    var curriculum = new Array<CurriculumItem>();

    for(let s of result.data.submissions) {  
        submissions.push(buildStudentCurriculumSubmission(s));
    }

    for(let student of result.data.students) {
        students.push(buildStudent(student));
    }

    for(let curriculumItem of result.data.curriculum) {
        curriculum.push(buildCurriculumItem(curriculumItem));
    }
    
    toReturn.Curriculum = curriculum;
    toReturn.Students = students;
    toReturn.Submissions = submissions;

    return toReturn;
}

export class MembershipResult {
    Result?: Membership;
    StatusCode: number;

    constructor(statusCode: number, result?: Membership) {
        this.StatusCode = statusCode;
        this.Result = result;
    }
}

export const touchMembership = async (instance: IPublicClientApplication, account: AccountInfo): Promise<boolean> => {
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!], instance, account);
    var statusCode = await getStatusCode(`${process.env.REACT_APP_MEMBERSHIP_URI as string}membership/touch`, accessToken);

    return statusCode === 200;
}

export const getMembership = async (instance: IPublicClientApplication, account: AccountInfo): Promise<MembershipResult> => {
    var accessToken = await getToken([process.env.REACT_APP_MEMBERSHIP_API_SCOPES!], instance, account);
    var result = await getData(`${process.env.REACT_APP_MEMBERSHIP_URI as string}membership`,accessToken);    
    
    if(result.data === undefined) {        
        if(result.statusCode === 403) {
            return new MembershipResult(result.statusCode);
        }

        if(result.statusCode === 404) {
            return new MembershipResult(result.statusCode, undefined);
        }

        return result.data;
    }

    let students: Student[] = [];

    
    for(const s of result.data.students) {
        students.push(buildStudent(s));
    }
    
    let membership: Membership = {
        Created: new Date(result.data.created),
        Modified: new Date(result.data.modified),
        Invited: new Date(result.data.invited),
        Id: result.data.id,
        Status: result.data.status as MembershipStatus,
        Students: students,
        TrainingFacilityTitle: result.data.trainingFacilityTitle,
        TrainingFacilityId: result.data.trainingFacilityId,
        Users: []
    };
    

    return new MembershipResult(200, membership);
}

const putData = async(uri: string, accessToken: string, blob: Blob): Promise<Response | undefined> => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    var body = null;

    if(blob !== undefined) {
        const formData = new FormData();
        formData.append('blob', blob);
        body = formData;
    }

    const options : RequestInit = {
        method: "PUT",
        headers: headers,        
        body: body
    };

    try {        
        const result = await fetch(uri, options);
        return result;
    } catch (ex) {
        console.error(ex);
        return undefined;
    }
}

const getStatusCode = async (uri:string, accessToken?: string): Promise<number> => {
    
    const headers = new Headers();

    if(accessToken !== null && accessToken !== undefined) {
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
    }

    const options = {
        method: "GET",
        headers: headers
    };

    try {        
        const result = await fetch(uri, options);
        return result.status;
    } catch (ex) {
        console.error(ex);
        return -1;
    }
};


const getData = async (uri:string, accessToken?: string): Promise<IResultWithStatusCode> => {
    
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    if(accessToken !== null && accessToken !== undefined) {
        headers.append("Authorization", bearer);
    }

    const options = {
        method: "GET",
        headers: headers
    };
   
    const result = await fetch(uri, options);

    if(!result.ok) {
        return { data: undefined, statusCode: result.status};
    }

    var data = await result.json();
    return { data: data, statusCode: result.status };
};

const postData = async(uri: string, accessToken: string, object: any): Promise<Response | undefined> => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");
    
    const options : RequestInit = {
        method: "POST",        
        headers: headers,        
        body: JSON.stringify(object)
    };

    try {        
        const result = await fetch(uri, options);
        return result;
    } catch (ex) {
        console.error(ex);
        return undefined
    }
}

const postDataNoAuth = async(uri: string, object: any): Promise<Response | undefined> => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    
    const options : RequestInit = {
        method: "POST",        
        headers: headers,        
        body: JSON.stringify(object)
    };

    try {        
        const result = await fetch(uri, options);
        return result;
    } catch (ex) {
        console.error(ex);
        return undefined
    }
}

const postFile = async (uri: string, accessToken: string, file: File): Promise<Response | undefined> => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    
    const formData = new FormData();
    formData.append('file', file);

    const options : RequestInit = {
        method: "POST",
        headers: headers,
        body: formData
    };

    try {        
        const result = await fetch(uri, options);
        return result;
    } catch (ex) {
        console.error(ex);
        return undefined;
    }
}