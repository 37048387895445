export interface IEventSummary {
    EventName: string;
    NumAthletes: number;
    DivisionsWithoutMatch: string[];
}

export interface IRoleSummary {
    Role: string;
    NumMembers: number;
}

export interface ICatalogSummary {
    ItemName: string;
    NumPurchases: number;
    Name: string;
    Configuration: string;
}

export interface IDisbursementSummary {
    Amount: number;
    AmountWitheld: number;
    CardProcessingFees: number;
    PerAthleteFee: number;
    PeriodStart: Date;
    PeriodEnd: Date;
    Created: Date;
    Method: string;
}

export interface ITournamentSummary {    
    Disbursements: IDisbursementSummary[];
    Events: IEventSummary[];
    Roles: IRoleSummary[];
    Catalog: ICatalogSummary[];
    CardProcessorFees: number;    
    NpnlFees: number;
    TotalCollected: number;
}

export class TournamentSummary implements ITournamentSummary {    
    Events: IEventSummary[];
    Roles: IRoleSummary[];
    Catalog: ICatalogSummary[];
    CardProcessorFees: number;
    NpnlFees: number;
    Disbursements: IDisbursementSummary[];
    TotalCollected: number;

    constructor(
        params: ITournamentSummary = {} as ITournamentSummary) {

        let {
            Events = [],
            Roles = [],
            Catalog = [],
            CardProcessorFees = 0,
            NpnlFees = 0,
            Disbursements = [],
            TotalCollected = 0
        } = params;

        this.Events = Events;
        this.Roles = Roles;
        this.Catalog = Catalog;
        this.CardProcessorFees = CardProcessorFees;
        this.NpnlFees = NpnlFees;
        this.Disbursements = Disbursements;
        this.TotalCollected = TotalCollected;
    }
}