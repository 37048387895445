import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { FC, useEffect, useState } from 'react';
import { AuthenticatedTemplate, useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import EventRegistrations from '../Dashboard/components/EventRegistrations';
import { getRegistrationsForMember } from '../../ApiService';
import Loader from '../../components/Loader';
import { ReviewCollection } from '../../model/ReviewCollection';
import { Tournament, TournamentLayout, TournamentType } from '../../model/Tournament';
import { TournamentRegistration } from '../../model/TournamentRegistration';
import { Stack, Text } from '@fluentui/react';

const Registrations: FC = () => {
    const appInsights = useAppInsightsContext();
    appInsights?.trackPageView({ name: "Registrations Page" });
    
    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const account = useAccount(accounts[0] || {});
    
    const [tournaments, setTournaments] = useState<Tournament[]>();
    const [reviews, setReviews] = useState<ReviewCollection[]>([]);
    const [registrations, setRegistrations] = useState<TournamentRegistration[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

        const fetchAsync = async () => {
            if (inProgress === "none" && account) {
                
                var reviews: ReviewCollection[] = [];
                var registrations: TournamentRegistration[] = [];
                var tournaments: Tournament[] = [];

                var memberRegistrations = await getRegistrationsForMember(instance, account);
                
                if (memberRegistrations === undefined ||
                    memberRegistrations.length === 0) {
                    setRegistrations([]);
                    setIsLoading(false);
                    return;
                }

                for (var grouping of memberRegistrations) {
                    tournaments.push({
                        AthleteCallMinutes: [],
                        BracketDownloadUri: grouping.BracketDownloadUri,
                        BracketLocations: [],
                        Catalog: grouping.Catalog,
                        CredentialTemplates: [],
                        Created: new Date(),
                        CustomerPaysConvenienceFee: false,
                        Description: '',
                        EventLandingPage: false,
                        EndDate: grouping.EndDate,
                        StartDate: grouping.StartDate,
                        Events: grouping.Events,
                        Id: grouping.TournamentId,
                        ImageUri: grouping.ImageUri,
                        JudgingTerms: '',
                        Layout: TournamentLayout.Default,
                        PurchaseGroups: [],
                        Pricing: [],
                        PricingStrategies: [],
                        RawPricingStrategies: [],
                        ReceiptImageUri: '',
                        RegistrationDescription: '',
                        StagingLocations: [],
                        Stakeholders: [],
                        StatementDescriptor: '',
                        StatusHubId: grouping.StatusHubId,
                        Students: grouping.Students,
                        Terms: '',
                        Timezone: '',
                        Title: grouping.Title,
                        Type: TournamentType.InPerson,
                        Location: grouping.Location,
                        HasEnded: grouping.HasEnded
                    });

                    registrations = ([...registrations, ...grouping.Registrations]);
                }
                
                setReviews(reviews);
                setTournaments(tournaments);
                setRegistrations(registrations);   
                setIsLoading(false);
            }
        }

        fetchAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, inProgress, account]);

    return (
        <AuthenticatedTemplate>
            <div style={{ padding: 20 }}>
                <Stack tokens={{ childrenGap: 20 }}>
                    <Text variant='xLarge'>Registrations</Text>
                    {registrations.length === 0 && !isLoading && <Text>No registrations found.</Text>}

                    {tournaments !== undefined && registrations !== undefined && !isLoading &&
                        <EventRegistrations tournaments={tournaments} reviews={reviews} registrations={registrations} />
                    }
                    { isLoading && <Loader Text='Just a moment...' />}
                </Stack>
            </div>
        </AuthenticatedTemplate>
    )
}

export default Registrations;